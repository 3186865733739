import { useCallback, useEffect, useState } from "react"
import { createPortal } from "react-dom"
import { useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { setQuery as _setQuery } from "../../app/slices/filters"
import {
  ColumnType,
  EditActionProps,
  RequestStatus,
} from "../../services/types/types"
import PopupDelete from "../PopupDelete/PopupDelete"
import PopupSettings from "../PopupSettings/PopupSettings"
import StatusActions from "../StatusActions/StatusActions"
import TransparentButton from "../ui/Button/Tranparent/TransparentButton"
import Input from "../ui/Input/Input"
import Icon from "../ui/icons"
import styles from "./styles.module.scss"

interface Props {
  confirm?: boolean
  deleteAction?: (id: number) => void
  editAction?: ({ id, isDeleted }: EditActionProps) => void
  selectedIds: number[]
  setSelectedIds: (arg: number[]) => void
  needSettings?: boolean
  isArchive?: boolean
  columns?: ColumnType[]
  popupTitle?: string
  setVisibleColums?: (arg: { [key: string]: boolean }) => void
  visibleColums: { [key: string]: boolean }
}

const Search = ({
  selectedIds,
  deleteAction,
  editAction,
  confirm = false,
  needSettings = true,
  isArchive = false,
  setSelectedIds,
  columns,
  popupTitle,
  setVisibleColums,
  visibleColums,
}: Props) => {
  const [query, setQuery] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const [isFinished, setIsFinished] = useState(false)
  const [isPopupOpened, setIsPopupOpened] = useState(false)
  const [isDeleteOpened, setIsDeleteOpened] = useState(false)
  const [isConfirmed, setIsConfirmed] = useState(false)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const isAdmin = useAppSelector((state) => state.user.isAdmin)

  const handleInputChange = (str: string) => {
    setQuery(str)
  }

  useEffect(() => {
    dispatch(_setQuery(query))
  }, [dispatch, query])

  useEffect(() => {
    if (isFinished) navigate(-1)
  }, [isFinished, navigate])

  const deleteItems = useCallback(() => {
    if (isArchive && deleteAction) {
      selectedIds.forEach(async (id) => {
        setIsLoading(true)

        try {
          setIsLoading(true)
          deleteAction(id)
          setIsFinished(true)
        } catch (error) {
          console.log(error)
        } finally {
          setIsLoading(false)
          setSelectedIds([])
        }
      })

      setIsConfirmed(false)
    } else if (!isArchive && editAction) {
      selectedIds.forEach(async (id) => {
        try {
          setIsLoading(true)
          editAction({ id, isDeleted: true })
        } catch (error) {
          console.log(error)
        } finally {
          setIsLoading(false)
          setSelectedIds([])
        }
      })

      setIsConfirmed(false)
    }
  }, [deleteAction, editAction, isArchive, selectedIds, setSelectedIds])

  useEffect(() => {
    if (isConfirmed) {
      deleteItems()
    }
  }, [deleteItems, isConfirmed])

  const restoreItems = () => {
    if (!editAction) return

    selectedIds.forEach(async (id) => {
      try {
        setIsLoading(true)
        editAction({ id, isDeleted: false })
        setIsFinished(true)
      } catch (error) {
        console.log(error)
      } finally {
        setIsLoading(false)
        setSelectedIds([])
      }
    })
  }

  const getActions = () => {
    return (
      selectedIds && (
        <div className={styles.actions}>
          {confirm && (
            <StatusActions
              selectedIds={selectedIds}
              status={RequestStatus.pending}
            />
          )}
          {isArchive && (
            <TransparentButton
              onClick={restoreItems}
              text="Восстановить выбранное"
              icon={<Icon icon={isHovered ? "chevronToLeft" : "arrowCircle"} />}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              disabled={isLoading}
            />
          )}
          <TransparentButton
            onClick={() => setIsDeleteOpened(true)}
            text={`Удалить выбранное ${isArchive ? "навсегда" : ""}`}
            variant="error"
            icon={<Icon icon="trashRed" />}
            disabled={isLoading}
          />
        </div>
      )
    )
  }

  return (
    <div className={styles.block}>
      {selectedIds.length === 0 ? (
        <div className={styles.block__input}>
          <Input
            onChange={handleInputChange}
            value={query}
            placeholder="Найти..."
          />
          {query && (
            <button onClick={() => setQuery(null)}>
              <Icon icon="cross" />
            </button>
          )}
        </div>
      ) : (
        getActions()
      )}
      {needSettings && isAdmin && (
        <button onClick={() => setIsPopupOpened(true)}>
          <Icon icon="settings" />
        </button>
      )}
      {columns && popupTitle && setVisibleColums
        ? isPopupOpened &&
          createPortal(
            <PopupSettings
              columns={columns}
              title={popupTitle}
              setIsPopupOpened={setIsPopupOpened}
              setVisibleColumns={setVisibleColums}
              visibleColumns={visibleColums}
            />,
            document.body,
          )
        : null}
      {isDeleteOpened &&
        createPortal(
          <PopupDelete
            setIsConfirmed={setIsConfirmed}
            ids={selectedIds}
            setIsPopupOpened={setIsDeleteOpened}
            showActionMessage={isArchive}
          />,
          document.body,
        )}
    </div>
  )
}

export default Search
