import cn from "classnames"
import { ButtonHTMLAttributes } from "react"
import { PropsButton } from "../Button"
import styles from "./styles.module.scss"

interface Props
  extends Omit<PropsButton, "variant">,
    ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "error" | "success" | "default"
}

const TransparentButton = ({
  text,
  variant = "default",
  icon,
  ...props
}: Props) => {
  return (
    <button
      {...props}
      className={cn(styles.btn, {
        [styles.btnError]: variant === "error",
        [styles.btnSuccess]: variant === "success",
      })}
    >
      {icon}
      {text}
    </button>
  )
}

export default TransparentButton
