const EditIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.75 6.99999H6.75C6.21957 6.99999 5.71086 7.2107 5.33579 7.58577C4.96071 7.96084 4.75 8.46955 4.75 8.99999V18C4.75 18.5304 4.96071 19.0391 5.33579 19.4142C5.71086 19.7893 6.21957 20 6.75 20H15.75C16.2804 20 16.7891 19.7893 17.1642 19.4142C17.5393 19.0391 17.75 18.5304 17.75 18V17M16.75 4.99998L19.75 7.99999M21.135 6.58499C21.5288 6.19114 21.7501 5.65697 21.7501 5.09998C21.7501 4.543 21.5288 4.00883 21.135 3.61498C20.7412 3.22114 20.207 2.99988 19.65 2.99988C19.093 2.99988 18.5588 3.22114 18.165 3.61498L9.75 12V15H12.75L21.135 6.58499Z"
        stroke="#58667C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default EditIcon
