import cn from "classnames"
import ru from "date-fns/locale/ru"
import DatePicker from "react-datepicker"
import "../../../styles/lib/datePicker.css"
import styles from "./styles.module.scss"
import { useRef } from "react"

interface Props {
  label?: string
  selected: Date | null
  setSelected: (date: Date | null) => void
  required?: boolean
  disabled?: boolean
  hideInput?: boolean
  minDate?: Date
  toggle?: (dateRef: any) => void
}

const DatePickerComponent = ({
  label = undefined,
  selected,
  setSelected,
  required = false,
  disabled = false,
  hideInput = false,
  minDate = undefined,
  toggle
}: Props) => {
  const dateRef = useRef<any>(null);

  return (
    <div className={styles.picker__wrapper}>
      {label && (
        <p className={styles.label}>
          {label}
          {required && <span>*</span>}
        </p>
      )}
      <DatePicker
        onInputClick={() => toggle && toggle(dateRef)}

        ref={dateRef}
        onChange={(date) => {
          setSelected(date)
        }}

        disabled={disabled}
        selected={selected}
        disabledKeyboardNavigation
        locale={ru}
        autoComplete="off"
        showPopperArrow={false}
        minDate={minDate}
        onKeyDown={(e) => e.preventDefault()}
        dateFormat={"dd.MM.yyyy"}
        className={cn(styles.picker, {
          [styles.pickerChoosen]: selected !== null,
          [styles.pickerHidden]: hideInput,
        })}
        wrapperClassName={cn(styles.wrapper, {
          [styles.wrapperHidden]: hideInput,
        })}
        calendarClassName={styles.calendar}
        name="date"
      />
    </div>
  )
}

export default DatePickerComponent
