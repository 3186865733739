const ShieldIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="42"
      viewBox="0 0 40 42"
      fill="none"
    >
      <g clipPath="url(#clip0_1883_57173)">
        <path
          d="M38.6906 4.87713C37.4144 4.05837 35.922 3.34965 34.3269 2.74443C29.3785 0.86632 23.4419 0 19.9384 0C17.0719 0 12.5812 0.580033 8.3329 1.81843V2.77148C8.32285 4.41452 8.40021 6.05699 8.56466 7.69243C12.4561 6.35799 17.1281 5.65952 19.9393 5.65952C23.4535 5.65952 29.8769 6.74965 34.0457 8.81147C33.8023 11.8273 32.882 16.1253 30.8776 20.6266C28.754 25.3965 25.7547 29.6031 22.0843 33.0115C23.0317 33.7576 24.6143 34.9325 26.2861 35.8175L27.0754 36.2353C37.1507 26.3337 39.9987 13.4182 39.9987 7.23363V5.71734L38.6906 4.87713Z"
          fill="#277DFF"
        />
        <path
          d="M9.10931 20.5018C7.10869 15.9565 6.18875 11.6166 5.94569 8.57137C5.69199 6.67776 5.59709 4.54216 5.59902 2.47058C4.03126 3.0751 2.56323 3.78226 1.30631 4.59865L0 5.44706V6.97531C0 14.2258 3.82694 30.6101 18.3116 40.7909L20.0323 42L21.7531 40.7909C22.9016 39.9837 23.9839 39.1403 25 38.2608C23.0071 37.1949 21.1266 35.7664 20.0323 34.8709C15.3823 31.1544 11.6299 26.2278 9.10931 20.5018Z"
          fill="#277DFF"
        />
      </g>
      <defs>
        <clipPath id="clip0_1883_57173">
          <rect width="40" height="42" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ShieldIcon
