import { useNavigate } from "react-router-dom"
import Icon from "../ui/icons"
import styles from "./styles.module.scss"

interface Props {
  title: string
  archive?: boolean
}

const GoBack = ({ title, archive = false }: Props) => {
  const navigate = useNavigate()

  const goBack = () => {
    navigate(-1)
  }

  return (
    <div className={styles.top}>
      <button onClick={goBack}>
        <Icon icon="chevron" />
      </button>
      <h1>{title}</h1>
      {archive && <p>(Архив удаленных данных)</p>}
    </div>
  )
}

export default GoBack
