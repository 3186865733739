import cn from "classnames"
import { Link } from "react-router-dom"
import styles from "./styles.module.scss"

interface Props {
  submenu: {
    id: number
    text: string
    link: string
    isVisibleForOperator: boolean
  }[]
  isHidden: boolean
  isAdmin: boolean
  activePath: string
}

const Submenu = ({ submenu, isAdmin, isHidden, activePath }: Props) => {
  return (
    <>
      <ul
        className={cn(styles.submenu, {
          [styles.submenuHidden]: isHidden,
        })}
      >
        {submenu
          .filter((link) => {
            if (isAdmin) return true
            return link.isVisibleForOperator
          })
          .map((link) => {
            const splitted = link.link.split("/")
            const _link = splitted[splitted.length - 1]

            return (
              <li
                key={link.id}
                className={cn(styles.submenu__item, {
                  [styles.submenu__itemActive]: activePath === _link,
                })}
              >
                <Link to={link.link}>{link.text}</Link>
              </li>
            )
          })}
      </ul>

      {/* toggleSubMenu отрабатывает */}
      <div className={styles.layout} />
    </>
  )
}

export default Submenu
