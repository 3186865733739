import { useEffect, useState } from "react"
import { useCloseOnEscape } from "../../services/hooks/useCloseOnEscape"
import { ColumnType } from "../../services/types/types"
import Button from "../ui/Button/Button"
import Icon from "../ui/icons"
import styles from "./styles.module.scss"

interface Props {
  setIsPopupOpened: (arg: boolean) => void
  title: string
  columns: ColumnType[]
  setVisibleColumns: (arg: { [key: string]: boolean }) => void
  visibleColumns: { [key: string]: boolean }
}

const PopupSettings = ({
  setIsPopupOpened,
  columns,
  title,
  setVisibleColumns,
  visibleColumns,
}: Props) => {
  const [allChecked, setAllChecked] = useState(false)
  const [cols, setCols] = useState<{ [key: string]: boolean }>({})

  const close = () => setIsPopupOpened(false)

  useCloseOnEscape({ handler: setIsPopupOpened })

  const save = () => {
    setVisibleColumns(cols)
    close()
  }

  const handleChange = (_value: string) => {
    const keys = Object.keys(cols)
    const finded = keys.find((key) => key === _value)
    const valueOfFindedKey = cols[finded as string]

    setCols((prev) => ({
      ...prev,
      [finded as string]: !valueOfFindedKey,
    }))
  }

  const toggleAllChecked = () => {
    setAllChecked((prev) => !prev)
  }

  const isItemChecked = (_value: string) => {
    return cols[_value]
  }

  useEffect(() => {
    const values = Object.values(columns)

    values.forEach((value) => {
      setCols((prev) => ({ ...prev, [value.value]: allChecked }))
    })
  }, [columns, allChecked])

  useEffect(() => {
    const visibleColumnsEntries = Object.entries(visibleColumns)

    visibleColumnsEntries.forEach((entry) => {
      const [key, value] = entry

      if (value) {
        setCols((prev) => ({ ...prev, [key]: true }))
      }
    })
  }, [visibleColumns])

  const isAllChecked = () => {
    const values = Object.values(cols).filter((value) => value)

    return columns.length === values.length
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.popup}>
        <h3 className={styles.popup__title}>Список полей</h3>
        <div className={styles.popup__body}>
          <p className={styles.popup__bodyTitle}>{title}</p>
          <ul className={styles.popup__bodyList}>
            <li className={styles.popup__bodyListItem}>
              <label>
                <input
                  type="checkbox"
                  checked={isAllChecked()}
                  onChange={toggleAllChecked}
                />
                <span>Выбрать все</span>
              </label>
            </li>
            {columns.map((column) => (
              <li key={column.id} className={styles.popup__bodyListItem}>
                <label>
                  <input
                    type="checkbox"
                    value={column.row ?? ""}
                    checked={isItemChecked(column.value)}
                    onChange={() => handleChange(column.value)}
                  />
                  <span>{column.row}</span>
                </label>
              </li>
            ))}
          </ul>
          <div className={styles.popup__bodyActions}>
            <Button text="Применить" onClick={save} />
            <Button text="Отменить" variant="unstyled" onClick={close} />
          </div>
        </div>
        <button className={styles.popup__cross} onClick={close}>
          <Icon icon="cross" />
        </button>
      </div>
      <div className={styles.layout} onClick={close} />
    </div>
  )
}

export default PopupSettings
