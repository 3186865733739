import { useEffect } from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"

const Main = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (pathname === "/") {
      navigate("/login")
    }
  }, [navigate, pathname])

  return (
    <>
      <Outlet />
    </>
  )
}

export default Main
