import { Dispatch, SetStateAction } from "react"
import ReactSelect from "react-select"
import { OptionType } from "../../../services/types/types"
import styles from "./styles.module.scss"

interface Props {
  label?: string | undefined
  setOption:
    | ((option: OptionType | null) => void)
    | Dispatch<SetStateAction<OptionType | null>>
  options: OptionType[]
  value: OptionType | null | undefined
  placeholder?: string
  required?: boolean
  disabled?: boolean
}

const Select = ({
  label = undefined,
  options = [],
  setOption,
  value,
  placeholder = "",
  required = false,
  disabled = false,
}: Props) => {
  const inactiveColor = "#cdd6e2"
  const inactiveColorDarker = "#b8c0ce"

  return (
    <label className={styles.wrapper}>
      {label && (
        <p className={styles.label}>
          {label}
          {required && <span>*</span>}
        </p>
      )}
      <ReactSelect
        onChange={setOption}
        value={value}
        isDisabled={disabled}
        placeholder={placeholder}
        menuPosition="fixed"
        options={options}
        maxMenuHeight={200}
        className={styles.select}
        required={required}
        styles={{
          control: (baseStyles, { isDisabled }) => ({
            ...baseStyles,
            borderRadius: 10,
            height: 40,
            backgroundColor: isDisabled ? inactiveColor : "#fff",
            border: `1px solid #d6deea`,
          }),
          indicatorSeparator: (baseStyles) => ({
            ...baseStyles,
            display: "none",
          }),
          placeholder: (baseStyles, { isDisabled }) => ({
            ...baseStyles,
            fontSize: 14,
            fontWeight: 300,
            color: isDisabled ? inactiveColorDarker : "initial",
          }),
          dropdownIndicator: (baseStyles, state) => ({
            ...baseStyles,
            transition: ".2s all ease-in-out",
            ...(state.selectProps.menuIsOpen
              ? { transform: "rotate(180deg)" }
              : {}),
          }),
          option: (baseStyles, state) => ({
            ...baseStyles,
            background: "#F6F9FF",
            borderBottom: "1px solid #EFEFF1",
            color: state.isSelected ? "#245fd6" : "initial",
          }),
          singleValue: (baseStyles, { isDisabled }) => ({
            ...baseStyles,
            color: isDisabled ? inactiveColorDarker : "#245fd6",
          }),
        }}
      />
    </label>
  )
}

export default Select
