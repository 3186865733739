import cn from "classnames"
import { useState } from "react"
import { createPortal } from "react-dom"
import { useLocation, useNavigate } from "react-router-dom"
import { useDeleteRequestMutation } from "../../app/api/requests"
import { useAppSelector } from "../../app/hooks"
import { REQUEST_TABLE_COLUMNS } from "../../services/constants"
import { useChangeIsDeleted } from "../../services/hooks/useChangeIsDeleted"
import { RequestItemType } from "../../services/types/types"
import { formatDateToRu } from "../../services/utils/formatDate"
import { getGridStyles } from "../../services/utils/getGridStyles"
import { getPassType } from "../../services/utils/getTexts"
import PopupDelete from "../PopupDelete/PopupDelete"
import PopupPass from "../PopupPass/PopupPass"
import StatusActions from "../StatusActions/StatusActions"
import Preloader from "../ui/Preloader/Preloader"
import Status from "../ui/Status/Status"
import Icon from "../ui/icons"
import styles from "./styles.module.scss"

interface Props {
  item: RequestItemType
  checkedItems: number[]
  setCheckedItems: (arg: any) => void
  showOnlyDeletedItems: boolean
  visibleColumns: { [key: string]: boolean }
}

const RequestsRow = ({
  item,
  checkedItems,
  setCheckedItems,
  showOnlyDeletedItems,
  visibleColumns,
}: Props) => {
  const {
    date,
    requestNumber,
    requestStatus,
    clientFullName,
    organization,
    passType,
  } = item.attributes
  const isAdmin = useAppSelector((state) => state.user.isAdmin)

  const navigate = useNavigate()
  const { pathname } = useLocation()

  const [isPopupOpened, setIsPopupOpened] = useState(false)
  const [isDeleteOpened, setIsDeleteOpened] = useState(false)

  const urlParts = pathname.split("/")
  const isArchivePage = urlParts[urlParts.length - 1] !== "archive"

  const [deleteRequest] = useDeleteRequestMutation()
  const { changeRequestIsDeleted, isLoading } = useChangeIsDeleted()

  const toggleChecked = (id: number) => {
    const finded = checkedItems.find((item) => item === id)

    if (finded) {
      const filtered = checkedItems.filter((item) => item !== finded)

      setCheckedItems(filtered)
    } else {
      setCheckedItems((prev: number[]) => [...prev, id])
    }
  }

  const deleteItem = async (id: number) => {
    try {
      isArchivePage
        ? await changeRequestIsDeleted({ id, isDeleted: true })
        : deleteRequest(id)
    } catch (error) {
      console.log(error)
    }
  }

  const restoreItem = async (id: number) => {
    try {
      await changeRequestIsDeleted({ id, isDeleted: false })
    } catch (error) {
      console.log(error)
    }
  }

  const goToAction = (id: number) => {
    navigate(`${pathname}/action/${id}`)
  }

  const style = getGridStyles({
    mainTable: REQUEST_TABLE_COLUMNS,
    visibleColumns,
  })
  const isChecked = checkedItems.some((_item) => _item === item.id)

  return (
    <li
      className={cn(styles.row, {
        [styles.row__hidden]: !isAdmin,
        [styles.row__arсhive]: showOnlyDeletedItems,
      })}
      style={style}
    >
      {isAdmin && (
        <div className={styles.row__item}>
          <input
            type="checkbox"
            onChange={() => toggleChecked(item.id)}
            checked={isChecked}
          />
        </div>
      )}
      {visibleColumns.date && (
        <div className={styles.row__item}>
          <span>{formatDateToRu(date)}</span>
        </div>
      )}
      {visibleColumns.requestNumber && (
        <div className={cn(styles.row__item, "oneLine")}>
          <span>{requestNumber}</span>
        </div>
      )}
      {visibleColumns.passType && (
        <div className={styles.row__item}>
          <span>{getPassType(passType)}</span>
        </div>
      )}
      {visibleColumns.fullName && (
        <div className={cn(styles.row__item, "oneLine")}>
          <span>{clientFullName}</span>
        </div>
      )}
      {visibleColumns.organization && (
        <div className={cn(styles.row__item, "oneLine")}>
          <span>{organization}</span>
        </div>
      )}
      {visibleColumns.requestStatus && (
        <div className={cn(styles.row__item, styles.alignLeft)}>
          <Status status={requestStatus} />
        </div>
      )}
      {isAdmin && visibleColumns.request && (
        <div
          className={cn(
            styles.row__item,
            styles.blueHover,
            styles.row__itemRequest,
          )}
        >
          <button onClick={() => setIsPopupOpened(true)}>
            <Icon icon="document" />
            <span>Смотреть</span>
          </button>
        </div>
      )}
      {isAdmin && visibleColumns.actions && !showOnlyDeletedItems && (
        <div className={cn(styles.row__item, styles.row__itemActions)}>
          <StatusActions status={requestStatus} data={item} />
        </div>
      )}
      {visibleColumns.edit && (
        <div className={styles.row__item}>
          {showOnlyDeletedItems ? (
            <button onClick={() => restoreItem(item.id)}>
              <Icon icon={"arrowCircleDark"} />
            </button>
          ) : (
            <button onClick={() => goToAction(item.id)}>
              <Icon icon={"edit"} />
            </button>
          )}
        </div>
      )}
      {isAdmin && visibleColumns.delete && (
        <div className={styles.row__item}>
          <button onClick={() => setIsDeleteOpened(true)}>
            <Icon icon="trash" />
          </button>
        </div>
      )}
      {isPopupOpened &&
        createPortal(
          <PopupPass setIsPopupOpened={setIsPopupOpened} id={`${item.id}`} />,
          document.body,
        )}
      {isLoading && createPortal(<Preloader />, document.body)}
      {isDeleteOpened &&
        createPortal(
          <PopupDelete
            action={deleteItem}
            ids={item.id}
            setIsPopupOpened={setIsDeleteOpened}
          />,
          document.body,
        )}
    </li>
  )
}

export default RequestsRow
