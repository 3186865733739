import cn from "classnames"
import { useState } from "react"
import { createPortal } from "react-dom"
import { useLocation, useNavigate } from "react-router-dom"
import { useDeletePassMutation } from "../../app/api/pass"
import { PASS_TABLE_COLUMNS } from "../../services/constants"
import { useChangeIsDeleted } from "../../services/hooks/useChangeIsDeleted"
import { PassItemType } from "../../services/types/types"
import { getGridStyles } from "../../services/utils/getGridStyles"
import { getValidityText } from "../../services/utils/getTexts"
import PopupDelete from "../PopupDelete/PopupDelete"
import Preloader from "../ui/Preloader/Preloader"
import Status from "../ui/Status/Status"
import Icon from "../ui/icons"
import styles from "./styles.module.scss"

interface Props {
  item: PassItemType
  checkedItems: number[]
  setChekedItems: (arg: any) => void
  showOnlyDeletedItems: boolean
  visibleColumns: { [key: string]: boolean }
}

const PassRow = ({
  checkedItems,
  item,
  setChekedItems,
  showOnlyDeletedItems,
  visibleColumns,
}: Props) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const [deleteRow] = useDeletePassMutation()
  const { changePassIsDeleted, isLoading } = useChangeIsDeleted()

  const [isDeleteOpened, setIsDeleteOpened] = useState(false)

  const urlParts = pathname.split("/")
  const isArchivePage = urlParts[urlParts.length - 1] !== "archive"
  const itemAttributes = item.attributes
  const requestAttributes = itemAttributes?.request

  const toggleChecked = (id: number) => {
    const finded = checkedItems.find((item) => item === id)

    if (finded) {
      const filtered = checkedItems.filter((item) => item !== finded)

      setChekedItems(filtered)
    } else {
      setChekedItems((prev: number[]) => [...prev, id])
    }
  }

  const deleteItem = async (id: number) => {
    isArchivePage
      ? await changePassIsDeleted({ id, isDeleted: true })
      : deleteRow(id)
  }

  const restoreItem = async (id: number) => {
    try {
      await changePassIsDeleted({ id, isDeleted: false })
    } catch (error) {
      console.log(error)
    }
  }

  const goToAction = (id: number) => {
    navigate(`${pathname}/action/${id}`)
  }

  const style = getGridStyles({ mainTable: PASS_TABLE_COLUMNS, visibleColumns })
  const isChecked = checkedItems.some((_item) => _item === item.id)

  return (
    <li
      className={cn(styles.row, {
        [styles.row__arсhive]: showOnlyDeletedItems,
      })}
      style={style}
    >
      <div className={styles.row__item}>
        <input
          type="checkbox"
          onChange={() => toggleChecked(item.id)}
          checked={isChecked}
        />
      </div>
      {visibleColumns.fullName && (
        <div className={cn(styles.row__item, "oneLine")}>
          <span>{requestAttributes?.data?.attributes.clientFullName}</span>
        </div>
      )}
      {visibleColumns.organization && (
        <div className={cn(styles.row__item, "oneLine")}>
          <span>{requestAttributes?.data?.attributes.organization}</span>
        </div>
      )}
      {visibleColumns.passStatus && (
        <div className={cn(styles.row__item, styles.alignLeft)}>
          <Status status={itemAttributes.passStatus} />
        </div>
      )}
      {visibleColumns.validity && (
        <div className={styles.row__item}>
          <span>{getValidityText(itemAttributes.validity)}</span>
        </div>
      )}
      {visibleColumns.phone && (
        <div className={styles.row__item}>
          <span>{itemAttributes.phone}</span>
        </div>
      )}
      {visibleColumns.department && (
        <div className={cn(styles.row__item, "oneLine")}>
          <span>{itemAttributes.department}</span>
        </div>
      )}
      {visibleColumns.position && (
        <div className={cn(styles.row__item, "oneLine")}>
          <span>{itemAttributes.position}</span>
        </div>
      )}
      {visibleColumns.edit && (
        <div className={styles.row__item}>
          {showOnlyDeletedItems ? (
            <button onClick={() => restoreItem(item.id)}>
              <Icon icon={"arrowCircleDark"} />
            </button>
          ) : (
            <button onClick={() => goToAction(item.id)}>
              <Icon icon={"edit"} />
            </button>
          )}
        </div>
      )}
      {visibleColumns.delete && (
        <div className={styles.row__item}>
          <button onClick={() => setIsDeleteOpened(true)}>
            <Icon icon="trash" />
          </button>
        </div>
      )}
      {isLoading && createPortal(<Preloader />, document.body)}
      {isDeleteOpened &&
        createPortal(
          <PopupDelete
            setIsPopupOpened={setIsDeleteOpened}
            ids={item.id}
            action={deleteItem}
          />,
          document.body,
        )}
    </li>
  )
}

export default PassRow
