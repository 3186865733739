import { FormEventHandler, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useLazyGetFileQuery, useUploadFileMutation } from "../../app/api/files"
import {
  useCreateRequestMutation,
  useEditRequestMutation,
  useLazyGetRequestQuery,
} from "../../app/api/requests"
import { useAppSelector } from "../../app/hooks"
import { REQUEST_TYPES_OPTIONS } from "../../services/constants"
import { OptionType, RequestStatus } from "../../services/types/types"
import { formatDateToRequest } from "../../services/utils/formatDate"
import GoBack from "../GoBack/GoBack"
import PhotoUpload from "../PhotoUpload/PhotoUpload"
import Button from "../ui/Button/Button"
import DatePickerComponent from "../ui/DatePicker/DatePicker"
import Input from "../ui/Input/Input"
import Select from "../ui/Select/Select"
import styles from "./styles.module.scss"

const RequestAction = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const isAdmin = useAppSelector((state) => state.user.isAdmin)

  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [errorField, setErrorField] = useState<string | null>(null)

  const [passType, setPassType] = useState<OptionType | null>(null)
  const [requestNumber, setRequestNumber] = useState<string | null>(null)
  const [organization, setOrganization] = useState<string | null>(null)
  const [fullName, setFullName] = useState<string | null>(null)
  const [date, setDate] = useState<Date | null>(null)
  const [clientImg, setClientImg] = useState<
    string | ArrayBuffer | null | undefined
  >(null)
  const [clientPhotoId, setClientPhotoId] = useState<string | null>(null)
  const [clientPhotoUrl, setClientPhotoUrl] = useState<string | null>(null)
  const [files, setFiles] = useState<FileList | null>(null)

  const [createRequest, { isLoading }] = useCreateRequestMutation()
  const [uploadPhoto] = useUploadFileMutation()
  const [getPhoto] = useLazyGetFileQuery()
  const [getRequest] = useLazyGetRequestQuery()
  const [editRequest] = useEditRequestMutation()

  const create: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault()

    const formattedDate = formatDateToRequest(date!)
    const formData = new FormData()

    formData.append("files", files?.[0] as File)

    const response = files?.[0]
      ? await uploadPhoto(formData).unwrap()
      : undefined

    const param = {
      id,
      data: {
        clientFullName: fullName!,
        date: formattedDate,
        passType: passType!.value,
        requestStatus: isAdmin ? RequestStatus.resolved : RequestStatus.pending,
        clientPhoto: response ? response[0].id : Number(clientPhotoId!),
        requestNumber: requestNumber!,
        organization: organization!,
        isDeleted: false,
      },
    }

    if (id) {
      const response = await editRequest(param)

      if ("error" in response) {
        setErrorMessage("Данное поле должно быть уникальным")
        setErrorField("Номер заявки")
        return
      }
    } else {
      const response = await createRequest(param)

      if ("error" in response) {
        setErrorMessage("Данное поле должно быть уникальным")
        setErrorField("Номер заявки")
        return
      }
    }

    setPassType(null)
    setOrganization(null)
    setRequestNumber(null)
    setFullName(null)
    setDate(null)
    setClientImg(null)

    navigate("/pass/requests")
  }

  useEffect(() => {
    if (id) {
      getRequest(id)
        .unwrap()
        .then(async (data) => {
          const attributes = data.data.attributes

          const findedPassType = REQUEST_TYPES_OPTIONS.find(
            (option) => option.value === attributes.passType,
          )

          getPhoto(`${attributes.clientPhoto?.data.id}`)
            .unwrap()
            .then((data) => {
              setClientPhotoUrl(data.url)
              setClientPhotoId(`${data.id}`)
            })

          setClientImg(attributes.clientPhoto?.data.attributes.name)
          setPassType(findedPassType!)
          setDate(new Date(attributes.date))
          setRequestNumber(attributes.requestNumber)
          setOrganization(attributes.organization)
          setFullName(attributes.clientFullName)
        })
    }
  }, [getPhoto, getRequest, id])

  const disabled =
    !fullName?.trim() ||
    fullName.trim().length < 5 ||
    !date ||
    !organization?.trim() ||
    organization.trim().length < 5 ||
    !passType?.value.trim() ||
    !requestNumber?.trim ||
    !clientImg ||
    isLoading

  const now = new Date()
  const year = now.getFullYear()
  const month = now.getMonth()

  return (
    <main className={styles.page}>
      <GoBack title={`${id ? "Редактировать" : "Создать"} заявку на пропуск`} />
      <form className={styles.form} onSubmit={create}>
        <PhotoUpload
          setClientImg={setClientImg}
          setFiles={setFiles}
          clientPhotoUrl={clientPhotoUrl}
        />
        <Input
          type="number"
          label="Номер заявки"
          value={requestNumber}
          onChange={setRequestNumber}
          required
          disabled={!isAdmin && !!id}
        />
        <Select
          label="Тип заявки"
          options={REQUEST_TYPES_OPTIONS}
          setOption={setPassType}
          value={passType}
          required={true}
          disabled={!isAdmin && !!id}
        />
        <DatePickerComponent
          label="Дата заявки"
          selected={date}
          setSelected={setDate}
          required={true}
          disabled={!isAdmin && !!id}
          minDate={new Date(year, month - 1)}
        />
        <Input
          value={organization}
          onChange={setOrganization}
          label="Организация"
          required={true}
        />
        <Input
          value={fullName}
          onChange={setFullName}
          label="ФИО"
          required={true}
        />
        <Button disabled={disabled} text="Сохранить" onClick={() => {}} />
        {errorMessage && (
          <div className={styles.error}>{`${errorMessage}: ${errorField}`}</div>
        )}
      </form>
    </main>
  )
}

export default RequestAction
