import { useState } from "react"
import { useCloseOnEscape } from "../../services/hooks/useCloseOnEscape"
import Button from "../ui/Button/Button"
import Icon from "../ui/icons"
import styles from "./styles.module.scss"

interface Props {
  setIsPopupOpened: (arg: boolean) => void
  action?: (id: number) => Promise<void>
  setIsConfirmed?: (arg: boolean) => void
  ids: number | number[]
  showActionMessage?: boolean
}

const PopupDelete = ({
  setIsPopupOpened,
  action,
  ids,
  setIsConfirmed,
  showActionMessage = false,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false)

  const close = () => {
    setIsPopupOpened(false)
  }

  useCloseOnEscape({ handler: setIsPopupOpened })

  const deleteAction = async () => {
    setIsLoading(true)

    if (action) {
      if (typeof ids !== "object") {
        const id = ids
        await action(id)
      } else {
        ids.forEach(async (id) => await action(id))
      }
    } else if (setIsConfirmed) {
      setIsConfirmed(true)
    }

    setIsLoading(false)
    close()
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.popup}>
        <button className={styles.popup__close} onClick={close}>
          <Icon icon="cross" />
        </button>
        <h3 className={styles.popup__title}>Удаление данных</h3>
        <div className={styles.popup__description}>
          <span className={styles.popup__descriptionText}>
            Вы действительно хотите удалить выбранные данные?
          </span>
          {showActionMessage && (
            <span className={styles.popup__descriptionWarning}>
              Данное действие имеет необратимый характер
            </span>
          )}
        </div>
        <div className={styles.popup__actions}>
          <Button
            disabled={isLoading}
            text="Да, удалить"
            onClick={deleteAction}
          />
          <Button
            disabled={isLoading}
            text="Отмена"
            onClick={close}
            variant="unstyled"
          />
        </div>
      </div>
      <div className={styles.layout} onClick={close} />
    </div>
  )
}

export default PopupDelete
