import cn from "classnames"
import { useState } from "react"
import Navigation from "../Navigation/Navigation"
import Icon from "../ui/icons"
import styles from "./style.module.scss"

const Aside = () => {
  const [isHidden, setIsHidden] = useState(false)

  const toggleHidden = () => {
    setIsHidden((prev) => !prev)
  }

  return (
    <aside
      className={cn(styles.aside, {
        [styles.asideHidden]: isHidden,
      })}
    >
      <div className={styles.aside__top}>
        <div className={styles.aside__logo}>
          <Icon icon="logo" />
          <h1 className={styles.aside__logoTitle}>Система безопасности</h1>
        </div>
        <Navigation isHidden={isHidden} />
        <div className={styles.aside__feedback}>
          <Icon icon="message" />
          <span>Обратная связь</span>
        </div>
      </div>
      <button className={styles.aside__hide} onClick={toggleHidden}>
        <Icon icon="chevron" />
        <span>Свернуть</span>
      </button>
    </aside>
  )
}

export default Aside
