import { FormEventHandler, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useGetKppsQuery } from "../../app/api/kpp"
import {
  useCreateVisitMutation,
  useEditVisitMutation,
  useLazyGetVisitQuery,
} from "../../app/api/visits"
import { useAppSelector } from "../../app/hooks"
import { DIRECTIONS_OPTIONS } from "../../services/constants"
import { DirectionEnum, OptionType } from "../../services/types/types"
import { formatDateToRequest } from "../../services/utils/formatDate"
import { getKppsOptions } from "../../services/utils/getOptions"
import GoBack from "../GoBack/GoBack"
import Button from "../ui/Button/Button"
import DatePickerComponent from "../ui/DatePicker/DatePicker"
import Input from "../ui/Input/Input"
import Select from "../ui/Select/Select"
import TimePicker from "../ui/TimePicker/TimePicker"
import styles from "./styles.module.scss"

const VisitsAction = () => {
  const isAdmin = useAppSelector((state) => state.user.isAdmin)
  const { id } = useParams()

  const navigate = useNavigate()
  const { data: kppsData } = useGetKppsQuery({})
  const [createVisit, { isLoading: createVisitLoading }] =
    useCreateVisitMutation()
  const [getVisit] = useLazyGetVisitQuery()
  const [editVisit, { isLoading: editIsLoading }] = useEditVisitMutation()

  const [fullName, setFullName] = useState<string | null>(null)
  const [date, setDate] = useState<Date | null>(null)
  const [kpp, setKpp] = useState<OptionType | null>(null)
  const [direction, setDirection] = useState<OptionType | null>(null)
  const [passNumber, setPassNumber] = useState<string | null>(null)
  const [minutes, setMinutes] = useState("")
  const [hours, setHours] = useState("")

  const kppsOptions = getKppsOptions(kppsData)

  useEffect(() => {
    if (id) {
      getVisit(id)
        .unwrap()
        .then((data) => {
          const dataAttributes = data.data.attributes
          const kppAttributes = dataAttributes.kpp.data.attributes
          const direction = dataAttributes.direction

          const splittedTime = dataAttributes.time.split(":")

          setFullName(dataAttributes.fullName)
          setDate(new Date(dataAttributes.date))
          setKpp({
            label: kppAttributes.name,
            value: String(dataAttributes.kpp.data.id),
          })
          setDirection({
            label: direction === DirectionEnum.access ? "вход" : "выход",
            value: direction,
          })
          setPassNumber(dataAttributes.passNumber)
          setHours(splittedTime[0])
          setMinutes(splittedTime[1])
        })
        .catch((error) => console.log("Get Visit >>>", error))
    }
  }, [getVisit, id])

  const onSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault()

    const formattedDate = formatDateToRequest(date!)

    const param = {
      id,
      data: {
        date: formattedDate,
        direction: direction?.value,
        fullName: fullName!,
        kpp: kpp?.value,
        passNumber: passNumber!,
        time: `${hours}:${minutes}`,
        isDeleted: false,
      },
    }

    try {
      if (id) {
        await editVisit(param)
      } else {
        await createVisit(param)
      }

      setFullName(null)
      setDate(null)
      setKpp(null)
      setDirection(null)
      setPassNumber(null)
      setMinutes("")
      setHours("")

      navigate("/pass/visits")
    } catch (error) {
      console.log(error)
    }
  }

  const disabled =
    !fullName?.trim() ||
    fullName.trim().length < 5 ||
    !date ||
    !kpp?.value ||
    !direction?.value ||
    !passNumber?.trim() ||
    !minutes.trim() ||
    !hours.trim() ||
    createVisitLoading ||
    editIsLoading

  const now = new Date()
  const year = now.getFullYear()
  const month = now.getMonth()

  return (
    <main>
      <GoBack title={`${id ? "Редактировать" : "Добавить"} посетителя`} />
      <form className={styles.form} onSubmit={onSubmit}>
        <Input onChange={setFullName} value={fullName} label="ФИО" required />
        <DatePickerComponent
          selected={date}
          label="Дата посещения"
          setSelected={setDate}
          required
          disabled={!isAdmin && !!id}
          minDate={new Date(year, month - 1)}
        />
        <TimePicker
          label="Время посещения"
          required
          hours={hours}
          minutes={minutes}
          setHours={setHours}
          setMinutes={setMinutes}
          disabled={!isAdmin && !!id}
        />
        <Select
          options={kppsOptions}
          setOption={setKpp}
          value={kpp}
          label="Номер КПП"
          required
          disabled={!isAdmin && !!id}
        />
        <Select
          options={DIRECTIONS_OPTIONS}
          setOption={setDirection}
          value={direction}
          label="Направление"
          required
          disabled={!isAdmin && !!id}
        />
        <Input
          onChange={setPassNumber}
          value={passNumber}
          required
          label="Номер пропуска"
          disabled={!isAdmin && !!id}
          type="number"
        />
        <Button disabled={disabled} onClick={() => {}} text="Сохранить" />
      </form>
    </main>
  )
}

export default VisitsAction
