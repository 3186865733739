import { IGetPassesParams } from "../types/api"

export const getPassesParams = ({
  query,
  showOnlyDeletedItems,
}: IGetPassesParams) => ({
  populate: "request",
  sort: "id:desc",
  ...(query !== null
    ? { [`filters[request][clientFullName][$containsi]`]: query }
    : {}),
  [`filters[isDeleted][$eq]`]: showOnlyDeletedItems,
})
