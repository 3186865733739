import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import Cookies from "js-cookie"
import { BASE_URL } from "../../services/constants"
import { IAuthParams, IAuthResponse } from "../../services/types/api"
import { UserType } from "../../services/types/types"
import { removeCreds, setUser } from "../slices/user"

export const userApi = createApi({
  reducerPath: "userApi",
  tagTypes: ["User"],
  baseQuery: fetchBaseQuery({ baseUrl: `${BASE_URL}` }),
  endpoints: (builder) => ({
    auth: builder.mutation<IAuthResponse, IAuthParams>({
      query: (creds) => ({
        url: "auth/local",
        body: creds,
        method: "POST",
      }),
    }),

    getMe: builder.query<UserType, string | undefined>({
      queryFn: async (token, api, options, baseQuery) => {
        if (!token) {
          api.dispatch(removeCreds())
          return { error: { error: "unauthorized", status: "CUSTOM_ERROR" } }
        }

        const response = await baseQuery({
          url: "/users/me",
          method: "GET",
          params: {
            populate: "role",
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        if (response.error) {
          Cookies.remove("token")
          api.dispatch(removeCreds())
          return { error: response.error }
        }

        if (!response.data) {
          Cookies.remove("token")
          api.dispatch(removeCreds())
          return { error: { error: "no data", status: "CUSTOM_ERROR" } }
        }

        const data = response.data as UserType

        api.dispatch(
          setUser({
            roleId: data.role.id,
          }),
        )

        return { data }
      },
    }),
  }),
})

export const { useAuthMutation, useGetMeQuery } = userApi
