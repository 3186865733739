import cn from "classnames"
import { PassStatus, RequestStatus } from "../../../services/types/types"
import styles from "./styles.module.scss"

interface Props {
  status: RequestStatus | PassStatus | undefined
  noBgPass?: boolean
}

const Status = ({ status, noBgPass = false }: Props) => {
  const isRequestStatus =
    status === RequestStatus.pending ||
    status === RequestStatus.rejected ||
    status === RequestStatus.resolved

  return isRequestStatus ? (
    <div
      className={cn(styles.status, {
        [styles.statusRejected]: status === RequestStatus.rejected,
        [styles.statusResolved]: status === RequestStatus.resolved,
      })}
    >
      {status === RequestStatus.pending
        ? "На рассмотрении"
        : status === RequestStatus.rejected
        ? "Отклонено"
        : "Одобрено"}
    </div>
  ) : (
    <div
      className={cn(styles.status, {
        [styles.statusResolved]: status === PassStatus.active,
        [styles.statusRejected]: status === PassStatus.fired,
        [styles.statusSimple]: noBgPass,
      })}
    >
      {status === PassStatus.active
        ? "Активный"
        : status === PassStatus.fired
        ? "Уволен"
        : "Временный"}
    </div>
  )
}

export default Status
