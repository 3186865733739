import { IconsType } from "../../../services/types/types"
import ArchiveIcon from "./Archive/Archive"
import BellIcon from "./Bell/Bell"
import BoxIcon from "./Box/Box"
import CalendarIcon from "./Calendar/Calendar"
import ChevronIcon from "./Chevron/Chevron"
import ChevronToLeftIcon from "./Chevron/ChevronToLeft"
import CircledArrowIcon from "./CircledArrow/CircledArrow"
import CircledArrowDarkIcon from "./CircledArrowDark/CircledArrowDark"
import CrossIcon from "./Cross/Cross"
import DashboardIcon from "./Dashboard/Dashboard"
import DocumentIcon from "./Document/Document"
import EditIcon from "./Edit/Edit"
import LogoIcon from "./Logo/Logo"
import LogoutIcon from "./Logout/Logout"
import MapIcon from "./Map/Map"
import MessageIcon from "./Message/Message"
import PlusIcon from "./Plus/Plus"
import RouteIcon from "./Route/Route"
import SettingsIcon from "./Settings/Settings"
import ShieldIcon from "./Shield/Shield"
import SortArrowsIcon from "./SortArrows/SortArrows"
import SortArrowsAscIcon from "./SortArrowsAsc/SortArrowsAsc"
import SortArrowsDescIcon from "./SortArrowsDesc/SortArrowsDesc"
import StaffIcon from "./Staff/Staff"
import SystemIcon from "./System/System"
import TableIcon from "./Table/Table"
import TabletIcon from "./Tablet/Tablet"
import TrashIcon from "./Trash/Trash"
import TrashRedIcon from "./TrashRed/TrashRed"
import UploadIcon from "./Upload/Upload"

interface Props {
  icon: IconsType
}

const Icon = ({ icon }: Props) => {
  return {
    logo: <LogoIcon />,
    dashboard: <DashboardIcon />,
    staff: <StaffIcon />,
    route: <RouteIcon />,
    calendar: <CalendarIcon />,
    tablet: <TabletIcon />,
    map: <MapIcon />,
    system: <SystemIcon />,
    box: <BoxIcon />,
    table: <TableIcon />,
    message: <MessageIcon />,
    chevron: <ChevronIcon />,
    chevronToLeft: <ChevronToLeftIcon />,
    bell: <BellIcon />,
    logout: <LogoutIcon />,
    plus: <PlusIcon />,
    archive: <ArchiveIcon />,
    edit: <EditIcon />,
    trash: <TrashIcon />,
    settings: <SettingsIcon />,
    document: <DocumentIcon />,
    trashRed: <TrashRedIcon />,
    upload: <UploadIcon />,
    cross: <CrossIcon />,
    shield: <ShieldIcon />,
    arrowCircle: <CircledArrowIcon />,
    arrowCircleDark: <CircledArrowDarkIcon />,
    sortArrows: <SortArrowsIcon />,
    sortArrowsDesc: <SortArrowsDescIcon />,
    sortArrowsAsc: <SortArrowsAscIcon />,
  }[icon]
}

export default Icon
