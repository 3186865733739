import { DirectionEnum, PassType, ValidityEnum } from "../types/types"

export const getPassType = (passType: PassType | undefined) => {
  if (passType === PassType.personal) {
    return "Перcональный"
  }
}

export const getDirectionText = (direction: DirectionEnum) => {
  if (direction === DirectionEnum.access) {
    return "вход"
  } else if (direction === DirectionEnum.exit) {
    return "выход"
  }
}

export const getValidityText = (validity: ValidityEnum) => {
  if (validity === ValidityEnum.indefinite) {
    return "Бессрочный"
  } else if (validity === ValidityEnum.temporary) {
    return "Временный"
  }
}
