import cn from "classnames"
import { useState } from "react"
import { useAppDispatch } from "../../app/hooks"
import { setRequestStatus } from "../../app/slices/filters"
import { RequestStatus } from "../../services/types/types"
import styles from "./styles.module.scss"

const types = [
  {
    id: 0,
    text: "Все",
    value: null,
  },
  {
    id: 1,
    text: "Одобренные",
    value: RequestStatus.resolved,
  },
  {
    id: 2,
    text: "На рассмотрении",
    value: RequestStatus.pending,
  },
  {
    id: 3,
    text: "Отклоненные",
    value: RequestStatus.rejected,
  },
]

const StatusFilters = () => {
  const [activeType, setActiveType] = useState<RequestStatus | null>(null)
  const dispatch = useAppDispatch()

  const chooseFilter = (status: RequestStatus | null) => {
    setActiveType(status)
    dispatch(setRequestStatus(status))
  }

  return (
    <div className={styles.filters}>
      {types.map((type) => (
        <button
          key={type.id}
          className={cn(styles.filters__item, {
            [styles.filters__itemActive]: type.value === activeType,
          })}
          onClick={() => chooseFilter(type.value)}
        >
          {type.text}
        </button>
      ))}
    </div>
  )
}

export default StatusFilters
