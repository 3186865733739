import {
  ColumnType,
  DirectionEnum,
  NavType,
  OptionType,
  PassStatus,
  PassType,
  RequestStatus,
  ValidityEnum,
} from "../types/types"

export const APP_PATH = "https://security.itsports.io/app"
export const BASE_URL = `${APP_PATH}/api`
export const ADMIN_ROLE_ID = 3
export const REQUEST_TYPES_OPTIONS: OptionType[] = [
  {
    label: "Персональный",
    value: PassType.personal,
  },
]

export const REQUEST_STATUS_OPTIONS: OptionType[] = [
  {
    label: "Одобрено",
    value: RequestStatus.resolved,
  },
  {
    label: "Отклонено",
    value: RequestStatus.rejected,
  },
  {
    label: "На рассмотрении",
    value: RequestStatus.pending,
  },
]

export const DIRECTIONS_OPTIONS: OptionType[] = [
  {
    label: "Вход",
    value: DirectionEnum.access,
  },
  {
    label: "Выход",
    value: DirectionEnum.exit,
  },
]

export const PASS_STATUS_OPTIONS: OptionType[] = [
  {
    label: "Активный",
    value: PassStatus.active,
  },
  {
    label: "Уволен",
    value: PassStatus.fired,
  },
  {
    label: "Временный",
    value: PassStatus.temporary,
  },
]

export const VALIDITY_OPTIONS: OptionType[] = [
  {
    label: "Бессрочный",
    value: ValidityEnum.indefinite,
  },
  {
    label: "Временный",
    value: ValidityEnum.temporary,
  },
]

export const NAV_LINKS: NavType[] = [
  {
    id: 0,
    text: "Система пропусков",
    icon: "system",
    link: "/pass",
    subMenu: [
      {
        id: 1,
        text: "Пропуска",
        link: "/pass/passes",
        isVisibleForOperator: false,
      },
      {
        id: 2,
        text: "Заявки на пропуск",
        link: "/pass/requests",
        isVisibleForOperator: true,
      },
      {
        id: 3,
        text: "История посещения объекта",
        link: "/pass/visits",
        isVisibleForOperator: true,
      },
    ],
  },
]

export const PASS_TABLE_COLUMNS: ColumnType[] = [
  {
    id: 0,
    row: "ФИО",
    show: true,
    value: "fullName",
  },
  {
    id: 1,
    row: "Огранизация",
    show: true,
    value: "organization",
  },
  {
    id: 2,
    row: "Статус пропуска",
    show: true,
    value: "passStatus",
  },
  {
    id: 3,
    row: "Срок действия",
    show: true,
    value: "validity",
  },
  {
    id: 4,
    row: "Номер телефона",
    show: true,
    value: "phone",
  },
  {
    id: 5,
    row: "Отдел",
    show: true,
    value: "department",
  },
  {
    id: 6,
    row: "Должность",
    show: true,
    value: "position",
  },
  {
    id: 7,
    row: "Редактировать",
    isVisibleInArchive: false,
    show: true,
    value: "edit",
  },
  {
    id: 8,
    row: "Удалить",
    show: true,
    value: "delete",
  },
]

export const REQUEST_TABLE_COLUMNS: ColumnType[] = [
  {
    id: 0,
    row: "Дата заявки",
    show: true,
    value: "date",
  },
  {
    id: 1,
    row: "Номер заявки",
    show: true,
    value: "requestNumber",
  },
  {
    id: 2,
    row: "Тип пропуска",
    show: true,
    value: "passType",
  },
  {
    id: 3,
    row: "ФИО",
    show: true,
    value: "fullName",
  },
  {
    id: 4,
    row: "Организация",
    show: true,
    value: "organization",
  },
  {
    id: 5,
    row: "Статус заявки",
    show: true,
    value: "requestStatus",
  },
  {
    id: 6,
    row: "Заявка",
    isVisibleByOperator: false,
    show: true,
    value: "request",
  },
  {
    id: 7,
    row: "Действия",
    isVisibleByOperator: false,
    show: true,
    value: "actions",
  },
  {
    id: 8,
    row: "Редактировать",
    isVisibleInArchive: false,
    show: true,
    value: "edit",
  },
  {
    id: 9,
    row: "Удалить",
    isVisibleByOperator: false,
    show: true,
    value: "delete",
  },
]

export const VISIT_TABLE_COLUMNNS: ColumnType[] = [
  {
    id: 0,
    row: "Дата",
    show: true,
    value: "date",
  },
  {
    id: 1,
    row: "Время",
    show: true,
    value: "time",
  },
  {
    id: 2,
    row: "ФИО",
    show: true,
    value: "fullName",
  },
  {
    id: 3,
    row: "Номер КПП",
    show: true,
    value: "kpp",
  },
  {
    id: 4,
    row: "Направление",
    show: true,
    value: "direction",
  },
  {
    id: 5,
    row: "Пропуск",
    show: true,
    value: "pass",
  },
  {
    id: 6,
    row: "Редактировать",
    isVisibleInArchive: false,
    show: true,
    value: "edit",
  },
  {
    id: 7,
    row: "Удалить",
    isVisibleByOperator: false,
    show: true,
    value: "delete",
  },
]
