const LogoIcon = () => {
  return (
    <svg
      width="41"
      height="42"
      viewBox="0 0 41 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1601_2469)">
        <path
          d="M39.1906 4.87713C37.9144 4.05837 36.422 3.34965 34.8269 2.74443C29.8785 0.86632 23.9419 0 20.4384 0C17.5719 0 13.0812 0.580033 8.8329 1.81843V2.77148C8.82285 4.41452 8.90021 6.05699 9.06466 7.69243C12.9561 6.35799 17.6281 5.65952 20.4393 5.65952C23.9535 5.65952 30.3769 6.74965 34.5457 8.81147C34.3023 11.8273 33.382 16.1253 31.3776 20.6266C29.254 25.3965 26.2547 29.6031 22.5843 33.0115C23.5317 33.7576 25.1143 34.9325 26.7861 35.8175L27.5754 36.2353C37.6507 26.3337 40.4987 13.4182 40.4987 7.23363V5.71734L39.1906 4.87713Z"
          fill="white"
        />
        <path
          d="M9.60931 20.5018C7.60869 15.9565 6.68875 11.6166 6.44569 8.57137C6.19199 6.67776 6.09709 4.54216 6.09902 2.47058C4.53126 3.0751 3.06323 3.78226 1.80631 4.59865L0.5 5.44706V6.97531C0.5 14.2258 4.32694 30.6101 18.8116 40.7909L20.5323 42L22.2531 40.7909C23.4016 39.9837 24.4839 39.1403 25.5 38.2608C23.5071 37.1949 21.6266 35.7664 20.5323 34.8709C15.8823 31.1544 12.1299 26.2278 9.60931 20.5018Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1601_2469">
          <rect
            width="40"
            height="42"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default LogoIcon
