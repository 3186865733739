import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import Cookies from "js-cookie"
import { BASE_URL } from "../../services/constants"
import {
  IUploadFileParams,
  IUploadFileResponse,
} from "../../services/types/api"

export const filesApi = createApi({
  reducerPath: "filesApi",
  tagTypes: ["Files"],
  baseQuery: fetchBaseQuery({ baseUrl: `${BASE_URL}/upload` }),
  endpoints: (builder) => ({
    uploadFile: builder.mutation<IUploadFileResponse[], IUploadFileParams>({
      query: (formData) => {
        const token = Cookies.get("token")

        return {
          url: "",
          body: formData,
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      },
      invalidatesTags: ["Files"],
    }),

    getFile: builder.query<IUploadFileResponse, string | undefined>({
      query: (id) => {
        const token = Cookies.get("token")

        return {
          url: `/files/${id}`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      },
      providesTags: ["Files"],
    }),
  }),
})

export const { useUploadFileMutation, useGetFileQuery, useLazyGetFileQuery } =
  filesApi
