import cn from "classnames"
import { useEffect, useState } from "react"
import { createPortal } from "react-dom"
import { useGetRequestsQuery } from "../../app/api/requests"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { setDateFilter } from "../../app/slices/filters"
import { REQUEST_TABLE_COLUMNS } from "../../services/constants"
import { useDebounced } from "../../services/hooks/useDebounced"
import { useWindowWidth } from "../../services/hooks/useWindowWidth"
import { getEditColumnContent } from "../../services/utils/getEditColumnContent"
import { getGridStyles } from "../../services/utils/getGridStyles"
import RequestsRow from "../RequestsRow/RequestsRow"
import Preloader from "../ui/Preloader/Preloader"
import Icon from "../ui/icons"
import styles from "./styles.module.scss"

interface Props {
  checkedItems: number[]
  setCheckedItems: (arg: number[]) => void
  showOnlyDeletedItems?: boolean
  visibleColumns: { [key: string]: boolean }
}

const RequestsTable = ({
  checkedItems,
  setCheckedItems,
  showOnlyDeletedItems = false,
  visibleColumns,
}: Props) => {
  const isAdmin = useAppSelector((state) => state.user.isAdmin)
  const { requestStatus, query, dateFilter } = useAppSelector(
    (state) => state.filters,
  )
  const debounced = useDebounced({ string: query, delay: 500 })
  const { width } = useWindowWidth()

  const dispatch = useAppDispatch()

  const [allChecked, setAllChecked] = useState(false)

  const { data, isLoading } = useGetRequestsQuery({
    status: requestStatus,
    query: debounced,
    showOnlyDeletedItems,
    dateSort: dateFilter,
  })

  useEffect(() => {
    if (allChecked && data) {
      const allIds = data.data.map((item) => item.id)

      setCheckedItems(allIds)
    } else {
      setCheckedItems([])
    }
  }, [allChecked, data, setCheckedItems])

  useEffect(() => {
    setAllChecked(false)
  }, [data])

  const setDateSort = () => {
    if (dateFilter === null) {
      dispatch(setDateFilter("desc"))
    } else if (dateFilter === "desc") {
      dispatch(setDateFilter("asc"))
    } else if (dateFilter === "asc") {
      dispatch(setDateFilter(null))
    }
  }

  const toggleSelected = () => {
    setAllChecked((prev) => !prev)
  }

  const currentSortIcon = () => {
    if (dateFilter === "desc") {
      return <Icon icon="sortArrowsDesc" />
    } else if (dateFilter === "asc") {
      return <Icon icon="sortArrowsAsc" />
    }

    return <Icon icon="sortArrows" />
  }

  const style = getGridStyles({
    mainTable: REQUEST_TABLE_COLUMNS,
    visibleColumns,
  })

  const editColText = getEditColumnContent({ showOnlyDeletedItems, width })

  const isAllChecked = data?.data.length
    ? data?.data.length === checkedItems.length
    : false

  return (
    <>
      <div
        className={cn(styles.head, {
          [styles.headHidden]: !isAdmin,
          [styles.headArchive]: showOnlyDeletedItems,
        })}
        style={style}
      >
        {isAdmin && (
          <div className={styles.item}>
            <input
              type="checkbox"
              onChange={toggleSelected}
              checked={isAllChecked}
            />
          </div>
        )}
        {visibleColumns.date && (
          <div className={styles.item}>
            <span>Дата заявки</span>
            <button onClick={setDateSort}>{currentSortIcon()}</button>
          </div>
        )}
        {visibleColumns.requestNumber && (
          <div className={styles.item}>
            <span>Номер заявки</span>
          </div>
        )}
        {visibleColumns.passType && (
          <div className={styles.item}>
            <span>Тип пропуска</span>
          </div>
        )}
        {visibleColumns.fullName && (
          <div className={styles.item}>
            <span>ФИО</span>
          </div>
        )}
        {visibleColumns.organization && (
          <div className={styles.item}>
            <span>Организация</span>
          </div>
        )}
        {isAdmin && visibleColumns.requestStatus && (
          <div className={styles.item}>
            <span>Статус заявки</span>
          </div>
        )}
        {visibleColumns.request && (
          <div className={styles.item}>
            <span>Заявка</span>
          </div>
        )}
        {isAdmin && visibleColumns.actions && !showOnlyDeletedItems && (
          <div className={styles.item}>
            <span>Действия</span>
          </div>
        )}
        {visibleColumns.edit && (
          <div className={styles.item}>
            <span>{editColText}</span>
          </div>
        )}
        {isAdmin && visibleColumns.delete && (
          <div className={styles.item}>
            <span>{width >= 1800 ? "Удалить" : "Уд-ть"}</span>
          </div>
        )}
      </div>
      <ul className={styles.body}>
        {data?.data.map((row) => (
          <RequestsRow
            key={row.id}
            item={row}
            checkedItems={checkedItems}
            setCheckedItems={setCheckedItems}
            showOnlyDeletedItems={showOnlyDeletedItems}
            visibleColumns={visibleColumns}
          />
        ))}
      </ul>
      {isLoading && createPortal(<Preloader />, document.body)}
    </>
  )
}

export default RequestsTable
