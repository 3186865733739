import cn from "classnames"
import { useEffect, useState } from "react"
import { createPortal } from "react-dom"
import { useGetPassesQuery } from "../../app/api/pass"
import { useAppSelector } from "../../app/hooks"
import { PASS_TABLE_COLUMNS } from "../../services/constants"
import { useDebounced } from "../../services/hooks/useDebounced"
import { useWindowWidth } from "../../services/hooks/useWindowWidth"
import { getEditColumnContent } from "../../services/utils/getEditColumnContent"
import { getGridStyles } from "../../services/utils/getGridStyles"
import PassRow from "../PassRow/PassRow"
import Preloader from "../ui/Preloader/Preloader"
import styles from "./styles.module.scss"

interface Props {
  checkedItems: number[]
  setCheckedItems: (arg: number[]) => void
  showOnlyDeletedItems?: boolean
  visibleColumns: { [key: string]: boolean }
}

const PassTable = ({
  checkedItems,
  setCheckedItems,
  showOnlyDeletedItems = false,
  visibleColumns,
}: Props) => {
  const query = useAppSelector((state) => state.filters.query)
  const isAdmin = useAppSelector((state) => state.user.isAdmin)
  const debounced = useDebounced({ string: query, delay: 500 })
  const [allChecked, setAllChecked] = useState(false)

  const { data, isLoading } = useGetPassesQuery({
    query: debounced,
    showOnlyDeletedItems,
  })

  useEffect(() => {
    if (allChecked && data) {
      const allIds = data.data.map((item) => item.id)

      setCheckedItems(allIds)
    } else {
      setCheckedItems([])
    }
  }, [allChecked, data, setCheckedItems])

  useEffect(() => {
    setAllChecked(false)
  }, [data])

  const toggleSelected = () => {
    setAllChecked((prev) => !prev)
  }

  const { width } = useWindowWidth()
  const editColText = getEditColumnContent({ showOnlyDeletedItems, width })
  const style = getGridStyles({ mainTable: PASS_TABLE_COLUMNS, visibleColumns })

  const isAllChecked = data?.data.length
    ? data?.data.length === checkedItems.length
    : false

  return (
    <>
      <div
        className={cn(styles.head, {
          [styles.headArchive]: showOnlyDeletedItems,
        })}
        style={style}
      >
        {isAdmin && (
          <div className={styles.item}>
            <input
              type="checkbox"
              onChange={toggleSelected}
              checked={isAllChecked}
            />
          </div>
        )}
        {visibleColumns.fullName && (
          <div className={styles.item}>
            <span>ФИО</span>
          </div>
        )}
        {visibleColumns.organization && (
          <div className={styles.item}>
            <span>Организация</span>
          </div>
        )}
        {visibleColumns.passStatus && (
          <div className={styles.item}>
            <span>Статус пропуска</span>
          </div>
        )}
        {visibleColumns.validity && (
          <div className={styles.item}>
            <span>Срок действия</span>
          </div>
        )}
        {visibleColumns.phone && (
          <div className={styles.item}>
            <span>Номер телефона</span>
          </div>
        )}
        {visibleColumns.department && (
          <div className={styles.item}>
            <span>Отдел</span>
          </div>
        )}
        {visibleColumns.position && (
          <div className={styles.item}>
            <span>Должность</span>
          </div>
        )}
        {visibleColumns.edit && (
          <div className={styles.item}>
            <span>{editColText}</span>
          </div>
        )}
        {visibleColumns.delete && (
          <div className={styles.item}>
            <span>{width >= 1800 ? "Удалить" : "Уд-ть"}</span>
          </div>
        )}
      </div>
      <ul className={styles.body}>
        {data?.data.map((row) => (
          <PassRow
            key={row.id}
            item={row}
            checkedItems={checkedItems}
            setChekedItems={setCheckedItems}
            showOnlyDeletedItems={showOnlyDeletedItems}
            visibleColumns={visibleColumns}
          />
        ))}
      </ul>
      {isLoading && createPortal(<Preloader />, document.body)}
    </>
  )
}

export default PassTable
