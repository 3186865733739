import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import Cookies from "js-cookie"
import { BASE_URL } from "../../services/constants"
import { IKppsReponse } from "../../services/types/api"

export const kppApi = createApi({
  reducerPath: "kppApi",
  tagTypes: ["Kpps"],
  baseQuery: fetchBaseQuery({ baseUrl: `${BASE_URL}/kpps` }),
  endpoints: (builder) => ({
    getKpps: builder.query<IKppsReponse, {}>({
      query: () => {
        const token = Cookies.get("token")

        return {
          url: "",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      },
      providesTags: ["Kpps"],
    }),
  }),
})

export const { useGetKppsQuery } = kppApi
