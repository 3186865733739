import cn from "classnames"
import { ButtonHTMLAttributes, ReactElement } from "react"
import styles from "./styles.module.scss"
export interface PropsButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: ReactElement
  text: string
  disabled?: boolean
  variant?: "default" | "transparent" | "unstyled"
}

const Button = ({
  text,
  variant = "default",
  icon = undefined,
  ...props
}: PropsButton) => {
  return (
    <button
      {...props}
      className={cn(styles.btn, {
        [styles.btnTransparent]: variant === "transparent",
        [styles.btnUnstyled]: variant === "unstyled",
      })}
    >
      {icon}
      {text}
    </button>
  )
}

export default Button
