import cn from "classnames"
import { useGetRequestQuery } from "../../app/api/requests"
import { useCloseOnEscape } from "../../services/hooks/useCloseOnEscape"
import { RequestStatus } from "../../services/types/types"
import { formatDateToRu } from "../../services/utils/formatDate"
import { getImgFromStrapi } from "../../services/utils/getImgFromStrapi"
import { getPassType, getValidityText } from "../../services/utils/getTexts"
import StatusActions from "../StatusActions/StatusActions"
import Status from "../ui/Status/Status"
import Icon from "../ui/icons"
import styles from "./styles.module.scss"

interface Props {
  setIsPopupOpened: (value: boolean) => void
  id: string
}

const PopupPass = ({ setIsPopupOpened, id }: Props) => {
  const { data, isLoading } = useGetRequestQuery(id)

  const dataAttributes = data?.data.attributes
  const passAttributes = dataAttributes?.pass?.data?.attributes

  const userImg = getImgFromStrapi(
    data?.data.attributes.clientPhoto?.data.attributes.url,
  )

  useCloseOnEscape({ handler: setIsPopupOpened })

  const close = () => {
    setIsPopupOpened(false)
  }

  if (isLoading) return <>Loading...</>

  return (
    <>
      <div className={styles.popup}>
        <button className={styles.popup__close} onClick={close}>
          <Icon icon="cross" />
        </button>
        <div className={styles.popup__top}>
          <div className={styles.popup__topImg}>
            <img src={userImg} alt="user" />
          </div>
          <div className={styles.popup__topPass}>
            <h2>{`Заявка на пропуск №${dataAttributes?.requestNumber}`}</h2>
            <span>({getPassType(dataAttributes?.passType)})</span>
          </div>
        </div>
        <div className={styles.popup__tabs}>
          <span>Описание</span>
        </div>
        <ul className={styles.popup__body}>
          <li className={styles.popup__bodyItem}>
            <p className={styles.popup__bodyItemLabel}>Статус</p>
            <div className={styles.popup__bodyItemText}>
              <Status status={dataAttributes?.requestStatus} />
            </div>
          </li>
          <li className={styles.popup__bodyItem}>
            <p className={styles.popup__bodyItemLabel}>Дата заявки</p>
            <p className={styles.popup__bodyItemText}>
              {formatDateToRu(dataAttributes?.date)}
            </p>
          </li>
          <li className={styles.popup__bodyItem}>
            <p className={styles.popup__bodyItemLabel}>ФИО</p>
            <p className={cn(styles.popup__bodyItemText, "oneLine")}>
              {dataAttributes?.clientFullName}
            </p>
          </li>
          <li className={styles.popup__bodyItem}>
            <p className={styles.popup__bodyItemLabel}>Организация</p>
            <p className={cn(styles.popup__bodyItemText, "oneLine")}>
              {dataAttributes?.organization}
            </p>
          </li>
          {passAttributes?.department && (
            <li className={styles.popup__bodyItem}>
              <p className={styles.popup__bodyItemLabel}>Отдел</p>
              <p className={styles.popup__bodyItemText}>
                {passAttributes?.department}
              </p>
            </li>
          )}
          {passAttributes?.position && (
            <li className={styles.popup__bodyItem}>
              <p className={styles.popup__bodyItemLabel}>Должность</p>
              <p className={styles.popup__bodyItemText}>
                {passAttributes?.position}
              </p>
            </li>
          )}
          {passAttributes?.phone && (
            <li className={styles.popup__bodyItem}>
              <p className={styles.popup__bodyItemLabel}>Номер телефона</p>
              <p className={styles.popup__bodyItemText}>
                {passAttributes?.phone}
              </p>
            </li>
          )}
          {passAttributes?.passStatus && (
            <li className={styles.popup__bodyItem}>
              <p className={styles.popup__bodyItemLabel}>Статус пропуска</p>
              <div className={styles.popup__bodyItemText}>
                <Status noBgPass={true} status={passAttributes?.passStatus} />
              </div>
            </li>
          )}
          {passAttributes?.validity && (
            <li className={styles.popup__bodyItem}>
              <p className={styles.popup__bodyItemLabel}>Срок действия</p>
              <p className={styles.popup__bodyItemText}>
                {getValidityText(passAttributes.validity)}
              </p>
            </li>
          )}
        </ul>
        <div className={styles.popup__actions}>
          <StatusActions
            data={data?.data}
            status={dataAttributes?.requestStatus}
            setIsPopupOpened={setIsPopupOpened}
          />
          {dataAttributes?.requestStatus !== RequestStatus.resolved && (
            <button onClick={close} className={styles.popup__actionsCancel}>
              Отмена
            </button>
          )}
        </div>
      </div>
      <div className={styles.layout} onClick={close} />
    </>
  )
}

export default PopupPass
