export const removeSecondsInTime = (time: string) =>
  `${time.split(":")[0]}:${time.split(":")[1]}`

export const formatTimeToRequest = ({
  hours,
  minutes,
}: {
  minutes: string
  hours: string
}) => `${hours}:${minutes}.000`
