import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import Cookies from "js-cookie"
import { BASE_URL } from "../../services/constants"
import {
  ICreateRequestParams,
  IEditRequestParams,
  IRequestsParams,
  IRequestsResponse,
} from "../../services/types/api"
import { RequestItemType } from "../../services/types/types"
import { getRequestsParams } from "../../services/utils/getReqestsParams"

export const requestsApi = createApi({
  reducerPath: "requestsApi",
  tagTypes: ["Requests"],
  baseQuery: fetchBaseQuery({ baseUrl: `${BASE_URL}/requests` }),
  endpoints: (builder) => ({
    getRequests: builder.query<IRequestsResponse, IRequestsParams>({
      query: (requestsParams) => {
        const token = Cookies.get("token")
        const params = getRequestsParams(requestsParams)

        return {
          url: "",
          params,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      },
      providesTags: ["Requests"],
    }),

    getRequest: builder.query<{ data: RequestItemType }, string>({
      query: (id) => {
        const token = Cookies.get("token")

        return {
          url: `/${id}`,
          params: {
            populate: ["clientPhoto", "pass"],
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      },
      providesTags: (_, __, id) => [{ type: "Requests", id }],
    }),

    createRequest: builder.mutation<unknown, ICreateRequestParams>({
      query: (body) => {
        const token = Cookies.get("token")

        return {
          url: "",
          method: "POST",
          body: body,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      },
      invalidatesTags: ["Requests"],
    }),

    editRequest: builder.mutation<unknown, IEditRequestParams>({
      query: ({ id, data }) => {
        const token = Cookies.get("token")

        return {
          url: `/${id}`,
          body: { data: data },
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      },
      invalidatesTags: ["Requests"],
    }),

    deleteRequest: builder.mutation<unknown, number>({
      query: (id) => {
        const token = Cookies.get("token")

        return {
          url: `/${id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      },
      invalidatesTags: ["Requests"],
    }),
  }),
})

export const {
  useGetRequestsQuery,
  useCreateRequestMutation,
  useGetRequestQuery,
  useLazyGetRequestQuery,
  useEditRequestMutation,
  useDeleteRequestMutation,
} = requestsApi
