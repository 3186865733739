import cn from "classnames"
import { useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { useAppSelector } from "../../app/hooks"
import { NAV_LINKS } from "../../services/constants"
import { NavType } from "../../services/types/types"
import Submenu from "../Submenu/Submenu"
import Icon from "../ui/icons"
import styles from "./style.module.scss"

interface Props {
  isHidden: boolean
}

const Navigation = ({ isHidden }: Props) => {
  const isAdmin = useAppSelector((state) => state.user.isAdmin)
  const [subMenuActive, setSubMenuActive] = useState(false)

  const { pathname } = useLocation()
  const splittedUrl = pathname.split("/").slice(1)

  const toggleSubMenu = () => {
    setSubMenuActive((prev) => !prev)
  }

  const renderNavbar = (_link: NavType) => {
    if ("subMenu" in _link) {
      const { id, subMenu, text } = _link

      return (
        <li
          key={id}
          className={cn(styles.nav__listItem, {
            [styles.nav__listItemActive]: subMenuActive,
            [styles.nav__listItemCurrent]:
              _link.link.substring(1) === splittedUrl[0],
          })}
        >
          <div className={styles.nav__listItemLink} onClick={toggleSubMenu}>
            <Icon icon={_link.icon} />
            <span className={styles.nav__listItemLinkText}>{text}</span>
            <Icon icon="chevron" />
            {subMenuActive && (
              <Submenu
                isAdmin={isAdmin}
                submenu={subMenu}
                isHidden={isHidden}
                activePath={splittedUrl[1]}
              />
            )}
          </div>
        </li>
      )
    }

    return (
      <li
        className={cn(styles.nav__listItem, {
          [styles.nav__listItemActive]: subMenuActive,
          [styles.nav__listItemCurrent]:
            _link.link.substring(1) === splittedUrl[1],
        })}
        key={_link.id}
      >
        <div className={styles.nav__listItemLink}>
          <Icon icon={_link.icon} />
          <Link className={styles.nav__listItemLinkText} to={_link.link}>
            {_link.text}
          </Link>
          <Icon icon="chevron" />
        </div>
      </li>
    )
  }

  return (
    <nav
      className={cn(styles.nav, {
        [styles.navHidden]: isHidden,
      })}
    >
      <ul className={styles.nav__list}>
        {NAV_LINKS.map((link) => renderNavbar(link))}
      </ul>
    </nav>
  )
}

export default Navigation
