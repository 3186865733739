import { useEffect } from "react"

interface Props {
  handler: (arg: boolean) => void
}

export const useCloseOnEscape = ({ handler }: Props) => {
  useEffect(() => {
    const keyboardHandller = (e: KeyboardEvent) => {
      if (e.code === "Escape") handler(false)
    }

    window.addEventListener("keyup", keyboardHandller)

    return () => window.removeEventListener("keyup", keyboardHandller)
  }, [handler])
}
