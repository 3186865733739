import Cookies from "js-cookie"
import { FormEventHandler, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAuthMutation } from "../../app/api/user"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { setUser } from "../../app/slices/user"
import Button from "../ui/Button/Button"
import Input from "../ui/Input/Input"
import Icon from "../ui/icons"
import styles from "./styles.module.scss"

const Login = () => {
  const [creds, setCreds] = useState({
    identifier: "",
    password: "",
  })
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [auth, { isLoading }] = useAuthMutation()
  const isAuthorized = useAppSelector((state) => state.user.authorized)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const authorize: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault()

    const trimmedCreds = {
      identifier: creds.identifier.trim(),
      password: creds.password.trim(),
    }

    try {
      const response = await auth(trimmedCreds).unwrap()

      const jwt = response.jwt

      dispatch(setUser({}))

      Cookies.set("token", jwt, {
        path: "/",
        expires: 99999,
        sameSite: "Strict",
        secure: true,
      })
    } catch (error) {
      setErrorMessage("Введенные данные неверны")
    }
  }

  useEffect(() => {
    if (isAuthorized) navigate("/pass/requests")
  }, [isAuthorized, navigate])

  useEffect(() => {
    setErrorMessage(null)
  }, [creds])

  const disabled = !creds.identifier || !creds.password || isLoading

  return (
    <main className={styles.main}>
      <div className={styles.logo}>
        <Icon icon="shield" />
        <span>Система безопасности</span>
      </div>
      <form className={styles.form} onSubmit={authorize}>
        <Input
          value={creds.identifier}
          onChange={(str) => setCreds((prev) => ({ ...prev, identifier: str }))}
          label="Логин"
          name="identifier"
        />
        <Input
          value={creds.password}
          onChange={(str) => setCreds((prev) => ({ ...prev, password: str }))}
          label="Пароль"
          type="password"
          name="password"
        />
        <Button onClick={() => {}} text="Вход" disabled={disabled} />
        {errorMessage && <p className={styles.error}>{errorMessage}</p>}
      </form>
    </main>
  )
}

export default Login
