import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import Cookies from "js-cookie"
import { BASE_URL } from "../../services/constants"
import {
  ICreatePassParams,
  IEditPassParams,
  IGetPassesParams,
  IPassesResponse,
} from "../../services/types/api"
import { PassItemType } from "../../services/types/types"
import { getPassesParams } from "../../services/utils/getPassesParams"
import { requestsApi } from "./requests"

export const passApi = createApi({
  reducerPath: "passApi",
  tagTypes: ["Passes"],
  baseQuery: fetchBaseQuery({ baseUrl: `${BASE_URL}/passes` }),
  endpoints: (builder) => ({
    getPasses: builder.query<IPassesResponse, IGetPassesParams>({
      query: (passesParams) => {
        const token = Cookies.get("token")
        const params = getPassesParams(passesParams)

        return {
          url: "",
          params,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      },
      providesTags: ["Passes"],
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        await queryFulfilled

        dispatch(requestsApi.util.invalidateTags(["Requests"]))
      },
    }),

    getPass: builder.query<{ data: PassItemType }, string>({
      query: (id) => {
        const token = Cookies.get("token")

        return {
          url: `/${id}`,
          params: {
            populate: "request",
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      },
      providesTags: (_, __, id) => [{ type: "Passes", id }],
    }),

    createPass: builder.mutation<unknown, ICreatePassParams>({
      query: (body) => {
        const token = Cookies.get("token")

        return {
          url: "",
          body,
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      },
      invalidatesTags: ["Passes"],
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        await queryFulfilled

        dispatch(requestsApi.util.invalidateTags(["Requests"]))
      },
    }),

    editPass: builder.mutation<unknown, IEditPassParams>({
      query: ({ id, data }) => {
        const token = Cookies.get("token")

        return {
          url: `/${id}`,
          body: { data },
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      },
      invalidatesTags: ["Passes"],
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        await queryFulfilled

        dispatch(requestsApi.util.invalidateTags(["Requests"]))
      },
    }),

    deletePass: builder.mutation<unknown, number>({
      query: (id) => {
        const token = Cookies.get("token")

        return {
          url: `/${id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      },
      invalidatesTags: ["Passes"],
    }),
  }),
})

export const {
  useGetPassesQuery,
  useCreatePassMutation,
  useDeletePassMutation,
  useLazyGetPassQuery,
  useEditPassMutation,
} = passApi
