import { Action, ThunkAction, configureStore } from "@reduxjs/toolkit"
import { filesApi } from "./api/files"
import { kppApi } from "./api/kpp"
import { passApi } from "./api/pass"
import { requestsApi } from "./api/requests"
import { userApi } from "./api/user"
import { visitsApi } from "./api/visits"
import FiltersReducer from "./slices/filters"
import UserReducer from "./slices/user"

export const store = configureStore({
  reducer: {
    filters: FiltersReducer,
    user: UserReducer,
    [userApi.reducerPath]: userApi.reducer,
    [visitsApi.reducerPath]: visitsApi.reducer,
    [requestsApi.reducerPath]: requestsApi.reducer,
    [filesApi.reducerPath]: filesApi.reducer,
    [kppApi.reducerPath]: kppApi.reducer,
    [passApi.reducerPath]: passApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      userApi.middleware,
      visitsApi.middleware,
      requestsApi.middleware,
      filesApi.middleware,
      kppApi.middleware,
      passApi.middleware,
    ),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
