const DocumentIcon = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5664 2.5V5.83333C12.5664 6.05435 12.6542 6.26631 12.8105 6.42259C12.9668 6.57887 13.1787 6.66667 13.3997 6.66667H16.7331M12.5664 2.5H6.73307C6.29105 2.5 5.86712 2.67559 5.55456 2.98816C5.242 3.30072 5.06641 3.72464 5.06641 4.16667V15.8333C5.06641 16.2754 5.242 16.6993 5.55456 17.0118C5.86712 17.3244 6.29105 17.5 6.73307 17.5H15.0664C15.5084 17.5 15.9324 17.3244 16.2449 17.0118C16.5575 16.6993 16.7331 16.2754 16.7331 15.8333V6.66667M12.5664 2.5L16.7331 6.66667M8.39974 14.1667H13.3997M8.39974 10.8333H13.3997"
        stroke="#58667C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default DocumentIcon
