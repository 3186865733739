import cn from "classnames"
import { useEffect, useRef, useState } from "react"
import Input from "../Input/Input"
import styles from "./styles.module.scss"

interface Props {
  setMinutes: (val: string) => void
  setHours: (val: string) => void
  hours: string
  minutes: string
  label?: string
  required?: boolean
  disabled?: boolean
}

const TimePickerComponent = ({
  setHours,
  setMinutes,
  label,
  required = false,
  hours,
  minutes,
  disabled = false,
}: Props) => {
  const [isTimeOpened, setIsTimeOpened] = useState(false)
  const timePickerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        timePickerRef.current &&
        !timePickerRef.current.contains(event.target)
      ) {
        setIsTimeOpened(false)
      }
    }

    document.addEventListener("click", handleClickOutside)

    return () => document.removeEventListener("click", handleClickOutside)
  }, [])

  useEffect(() => {
    if (hours && minutes) {
      setIsTimeOpened(false)
    }
  }, [hours, minutes])

  const _hours = Array(24)
    .fill(null)
    .map((_, index) => {
      const value = index + 1

      if (value < 10) return "0" + value
      if (value === 24) return "00"
      return value
    })

  const _minutes = Array(60)
    .fill(null)
    .map((_, index) => {
      if (index < 10) return "0" + index
      return index
    })

  const onClickMinutes = (minutes: string) => {
    setMinutes(minutes)
  }

  const onClickHours = (hours: string) => {
    setHours(hours)
  }

  return (
    <div ref={timePickerRef} className={styles.wrapper}>
      <Input
        label={label}
        required={required}
        value={`${hours}${hours ? ":" : ""}${minutes}`}
        onChange={() => {}}
        onFocus={() => setIsTimeOpened(true)}
        disabled={disabled}
      />
      {isTimeOpened && (
        <div className={cn(styles.time)}>
          <ul>
            {_hours.map((item) => (
              <li key={item}>
                <span onClick={() => onClickHours(String(item))}>{item}</span>
              </li>
            ))}
          </ul>
          <ul>
            {_minutes.map((item) => (
              <li key={item}>
                <span onClick={() => onClickMinutes(String(item))}>{item}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}

export default TimePickerComponent
