import { IUploadFileResponse } from "./api"

export type UserType = {
  id: number
  username: string
  email: string
  provider: string
  confirmed: boolean
  blocked: boolean
  createdAt: string
  updatedAt: string
  role: RoleType
}

export type RoleType = {
  createdAt: string
  description: string
  id: number
  name: string
  type: string
  updatedAt: string
}

export type IconsType =
  | "logo"
  | "dashboard"
  | "staff"
  | "route"
  | "calendar"
  | "tablet"
  | "map"
  | "system"
  | "box"
  | "table"
  | "message"
  | "chevron"
  | "bell"
  | "logout"
  | "plus"
  | "archive"
  | "edit"
  | "trash"
  | "settings"
  | "document"
  | "trashRed"
  | "upload"
  | "cross"
  | "shield"
  | "arrowCircle"
  | "chevronToLeft"
  | "sortArrows"
  | "sortArrowsDesc"
  | "sortArrowsAsc"
  | "arrowCircleDark"

export interface NavLinkType {
  icon: string
  text: string
  hasSubMenu: boolean
  link: string | null
  subMenu: SubMenuItem[]
}

export interface SubMenuItem
  extends Omit<NavLinkType, "hasSubMenu" | "icon" | "subMenu"> {}

export type RequestItemType = {
  id: number
  attributes: {
    clientFullName: string
    createdAt: string
    date: string
    organization: string
    passType: PassType
    isDeleted: boolean
    requestNumber: string
    requestStatus: RequestStatus
    updatedAt: string
    clientPhoto?: {
      data: {
        id: number
        attributes: Omit<IUploadFileResponse, "id">
      }
    }
    pass: {
      data: PassItemType | null
    }
  }
}

export type PassItemType = {
  id: number
  attributes: {
    createdAt: string
    department: string
    passStatus: PassStatus
    passNumber: string
    isDeleted: boolean
    phone: string
    position: string
    request: {
      data: RequestItemType | null
    }
    updatedAt: string
    validity: ValidityEnum
  }
}

export type VisitItemType = {
  id: number
  attributes: {
    createdAt: string
    date: string
    direction: DirectionEnum
    fullName: string
    passNumber: string
    isDeleted: boolean
    time: string
    updatedAt: string
    kpp: {
      data: {
        id: number
        attributes: {
          createdAt: string
          name: string
          updatedAt: string
        }
      }
    }
  }
}

export type KppType = {
  id: number
  attributes: {
    createdAt: string
    name: string
    updatedAt: string
  }
}

export type ClientType = {
  data: {
    id: number
    attributes: {
      createdAt: string
      fullName: string
      phone: string
      updatedAt: string
    }
  }
}

export type OrganizationType = {
  data: {
    id: number
    attributes: {
      createdAt: string
      name: string
      updatedAt: string
    }
  }
}

export enum RequestStatus {
  "pending" = "pending",
  "rejected" = "rejected",
  "resolved" = "resolved",
}

export enum PassType {
  "personal" = "personal",
}

export enum PassStatus {
  "active" = "active",
  "fired" = "fired",
  "temporary" = "temporary",
}

export enum DirectionEnum {
  "access" = "access",
  "exit" = "exit",
}

export enum ValidityEnum {
  "indefinite" = "indefinite",
  "temporary" = "temporary",
}

export type OptionType = {
  label: string
  value: string
}

export type ColumnType = {
  id: number
  row: string | null
  isVisibleByOperator?: boolean
  isVisibleInArchive?: boolean
  show: boolean
  value: string
}

export type CheckedColumnType = {
  id: number
  isChecked: boolean
}

export type NavType =
  | {
      id: number
      text: string
      icon: IconsType
      link: string
      subMenu: {
        id: number
        text: string
        link: string
        isVisibleForOperator: boolean
      }[]
    }
  | {
      id: number
      text: string
      link: string
      icon: IconsType
    }

export type CustomErrorType = {
  status: number
  data: {
    data: unknown
    error: {
      details: {
        errors: {
          message: string
          name: string
          path: string[]
        }[]
      }
      message: string
      name: string
      status: number
    }
  }
}

export interface EditActionProps {
  id: number | null
  isDeleted: boolean
}

export type SortType = "asc" | "desc" | null

export type RowsType = "request" | "visit" | "pass"
