import ReactInputMask from "react-input-mask"
import styles from "./styles.module.scss"

interface Props {
  placeholder?: string
  onChange: (str: string) => void
  value: string | null
  label?: string
  type?: string
  required?: boolean
  disabled?: boolean
  onFocus?: () => void
  name?: string
}

const Input = ({
  onChange,
  value,
  placeholder = undefined,
  label = undefined,
  type = "text",
  required = false,
  disabled = false,
  onFocus,
  name = undefined,
}: Props) => {
  return (
    <label className={styles.wrapper}>
      {label && (
        <p className={styles.label}>
          {label}
          {required && <span>*</span>}
        </p>
      )}
      {type !== "tel" ? (
        <input
          onFocus={onFocus}
          required={required}
          className={styles.input}
          type={type}
          value={value ?? ""}
          placeholder={placeholder}
          onChange={(e) => onChange(e.target.value)}
          disabled={disabled}
          name={name}
        />
      ) : (
        <ReactInputMask
          mask={"+7(999)999-99-99"}
          onChange={(e) => onChange(e.target.value)}
          value={value ?? ""}
          required={required}
          onFocus={onFocus}
          className={styles.input}
          disabled={disabled}
          name={name}
        />
      )}
    </label>
  )
}

export default Input
