import { IGetVisitParams } from "../types/api"

export const getVisitsParams = ({
  dateSort,
  query,
  showOnlyDeletedItems,
}: IGetVisitParams) => ({
  populate: "kpp",
  ...(query ? { [`filters[fullName][$containsi]`]: query } : {}),
  [`filters[isDeleted][$eq]`]: showOnlyDeletedItems,
  ...(dateSort !== null
    ? { sort: `date:${dateSort}` }
    : {
        sort: "id:desc",
      }),
})
