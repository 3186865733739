import { IKppsReponse, IRequestsResponse } from "../types/api"
import { OptionType } from "../types/types"

export const getKppsOptions = (data: IKppsReponse | undefined): OptionType[] =>
  data?.data.map((item) => ({
    label: item.attributes.name,
    value: `${item.id}`,
  })) ?? []

export const getRequestsOptions = (
  data: IRequestsResponse | undefined,
): OptionType[] =>
  data?.data
    .filter((item) => item?.attributes?.pass?.data === null)
    .map((item) => ({
      label: item.attributes.clientFullName,
      value: `${item.id}`,
    })) ?? []
