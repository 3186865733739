import { CSSProperties } from "react"
import { ColumnType } from "../types/types"

interface Props {
  mainTable: ColumnType[]
  visibleColumns: { [key: string]: boolean }
}

export const getGridStyles = ({
  mainTable,
  visibleColumns,
}: Props): CSSProperties => {
  const filteredValues = Object.values(visibleColumns).filter((value) => value)

  return visibleColumns && filteredValues.length !== mainTable.length
    ? {
        gridTemplateColumns: `63px repeat(${filteredValues.length}, 1fr)`,
      }
    : {}
}
