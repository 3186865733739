interface Props {
  width: number
  showOnlyDeletedItems: boolean
}

export const getEditColumnContent = ({
  showOnlyDeletedItems,
  width,
}: Props) => {
  if (showOnlyDeletedItems) {
    return width >= 1800 ? "Восстановить" : "Вос-ть"
  } else {
    return width >= 1800 ? "Редактировать" : "Ред-ть"
  }
}
