const SortArrowsAscIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M3.5 9L7.5 5M7.5 5L11.5 9M7.5 5V19"
        stroke="#245fd6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M21.5 15L17.5 19M17.5 19L13.5 15M17.5 19V5"
        stroke="#58667c"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  )
}

export default SortArrowsAscIcon
