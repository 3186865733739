import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import Cookies from "js-cookie"
import { ADMIN_ROLE_ID } from "../../services/constants"

interface IUserState {
  authorized: boolean
  isAdmin: boolean
}

const initialState: IUserState = {
  authorized: false,
  isAdmin: false,
}

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<{ roleId?: number }>) => {
      state.authorized = true
      state.isAdmin = action.payload.roleId === ADMIN_ROLE_ID
    },
    removeCreds: () => {
      Cookies.remove("token")
      return initialState
    },
  },
})

export const { setUser, removeCreds } = userSlice.actions
export default userSlice.reducer
