import { useState } from "react"
import { useDeletePassMutation } from "../../app/api/pass"
import { PASS_TABLE_COLUMNS } from "../../services/constants"
import { useChangeIsDeleted } from "../../services/hooks/useChangeIsDeleted"
import { useGetInitialVisibleColumns } from "../../services/hooks/useGetInitialVisibleColumns"
import GoBack from "../GoBack/GoBack"
import PassTable from "../PassTable/PassTable"
import Search from "../Search/Search"
import styles from "./styles.module.scss"

const PassArchive = () => {
  const [checkedItems, setCheckedItems] = useState<number[]>([])
  const { visibleColumns } = useGetInitialVisibleColumns({
    columns: PASS_TABLE_COLUMNS,
  })
  const [deletePass] = useDeletePassMutation()
  const { changePassIsDeleted } = useChangeIsDeleted()

  return (
    <main>
      <GoBack title="Пропуска" archive={true} />
      <div className={styles.grid}>
        <Search
          deleteAction={deletePass}
          editAction={changePassIsDeleted}
          selectedIds={checkedItems}
          needSettings={false}
          isArchive={true}
          setSelectedIds={setCheckedItems}
          visibleColums={visibleColumns}
        />
        <PassTable
          checkedItems={checkedItems}
          setCheckedItems={setCheckedItems}
          showOnlyDeletedItems={true}
          visibleColumns={visibleColumns}
        />
      </div>
    </main>
  )
}

export default PassArchive
