import { useState } from "react"
import { useDeleteRequestMutation } from "../../app/api/requests"
import { REQUEST_TABLE_COLUMNS } from "../../services/constants"
import { useChangeIsDeleted } from "../../services/hooks/useChangeIsDeleted"
import { useGetInitialVisibleColumns } from "../../services/hooks/useGetInitialVisibleColumns"
import GoBack from "../GoBack/GoBack"
import RequestsTable from "../RequestsTable/RequestsTable"
import Search from "../Search/Search"
import styles from "./styles.module.scss"

const RequestArchive = () => {
  const [checkedItems, setCheckedItems] = useState<number[]>([])
  const { visibleColumns } = useGetInitialVisibleColumns({
    columns: REQUEST_TABLE_COLUMNS,
  })
  const [deleteRequest] = useDeleteRequestMutation()
  const { changeRequestIsDeleted } = useChangeIsDeleted()

  return (
    <main>
      <GoBack title="Заявки на пропуск" archive={true} />
      <div className={styles.grid}>
        <Search
          deleteAction={deleteRequest}
          editAction={changeRequestIsDeleted}
          selectedIds={checkedItems}
          needSettings={false}
          isArchive={true}
          setSelectedIds={setCheckedItems}
          visibleColums={visibleColumns}
        />
        <RequestsTable
          checkedItems={checkedItems}
          setCheckedItems={setCheckedItems}
          showOnlyDeletedItems={true}
          visibleColumns={visibleColumns}
        />
      </div>
    </main>
  )
}

export default RequestArchive
