import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import Cookies from "js-cookie"
import { BASE_URL } from "../../services/constants"
import {
  ICreateVisitParams,
  IEditVisitParams,
  IGetVisitParams,
  IVisitReponse,
  IVisitsResponse,
} from "../../services/types/api"
import { getVisitsParams } from "../../services/utils/getVisitParams"

export const visitsApi = createApi({
  reducerPath: "visitsdApi",
  tagTypes: ["Visits"],
  baseQuery: fetchBaseQuery({ baseUrl: `${BASE_URL}/visits` }),
  endpoints: (builder) => ({
    getVisits: builder.query<IVisitsResponse, IGetVisitParams>({
      query: (visitParams) => {
        const token = Cookies.get("token")
        const params = getVisitsParams(visitParams)

        return {
          url: "",
          params,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      },
      providesTags: ["Visits"],
    }),

    getVisit: builder.query<IVisitReponse, string>({
      query: (id) => {
        const token = Cookies.get("token")

        return {
          url: `/${id}`,
          params: {
            populate: "kpp",
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      },
      providesTags: (_, __, id) => [{ type: "Visits", id }],
    }),

    editVisit: builder.mutation<unknown, IEditVisitParams>({
      query: ({ data, id }) => {
        const token = Cookies.get("token")

        return {
          url: `/${id}`,
          body: { data },
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      },
      invalidatesTags: ["Visits"],
    }),

    createVisit: builder.mutation<unknown, ICreateVisitParams>({
      query: (body) => {
        const token = Cookies.get("token")

        return {
          url: "",
          body,
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      },
      invalidatesTags: ["Visits"],
    }),

    deleteVisit: builder.mutation<unknown, number>({
      query: (id) => {
        const token = Cookies.get("token")

        return {
          url: `/${id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      },
      invalidatesTags: ["Visits"],
    }),
  }),
})

export const {
  useGetVisitsQuery,
  useDeleteVisitMutation,
  useCreateVisitMutation,
  useLazyGetVisitQuery,
  useEditVisitMutation,
} = visitsApi
