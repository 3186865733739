import { IRequestsParams } from "../types/api"

export const getRequestsParams = ({
  query,
  showOnlyDeletedItems,
  status,
  dateSort = null,
}: IRequestsParams) => ({
  populate: ["pass"],
  ...(status !== null ? { [`filters[requestStatus][$eq]`]: status } : {}),
  ...(query !== null ? { [`filters[clientFullName][$containsi]`]: query } : {}),
  [`filters[isDeleted][$eq]`]: showOnlyDeletedItems,
  ...(dateSort !== null
    ? { sort: `date:${dateSort}` }
    : {
        sort: "id:desc",
      }),
})
