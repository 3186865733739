import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RequestStatus, SortType } from "./../../services/types/types"

interface IFiltersState {
  requestStatus: RequestStatus | null
  query: string | null
  dateFilter: SortType
}

const initialState: IFiltersState = {
  requestStatus: null,
  query: null,
  dateFilter: null,
}

const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setRequestStatus: (state, action: PayloadAction<RequestStatus | null>) => {
      state.requestStatus = action.payload
    },
    setQuery: (state, action: PayloadAction<string | null>) => {
      state.query = action.payload
    },
    setDateFilter: (state, action: PayloadAction<SortType>) => {
      state.dateFilter = action.payload
    },
  },
})

export const { setRequestStatus, setQuery, setDateFilter } =
  filtersSlice.actions
export default filtersSlice.reducer
