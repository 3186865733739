import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { PASS_TABLE_COLUMNS } from "../../services/constants"
import { useChangeIsDeleted } from "../../services/hooks/useChangeIsDeleted"
import { useGetInitialVisibleColumns } from "../../services/hooks/useGetInitialVisibleColumns"
import PassTable from "../PassTable/PassTable"
import Search from "../Search/Search"
import Button from "../ui/Button/Button"
import Icon from "../ui/icons"
import styles from "./styles.module.scss"

const Pass = () => {
  const [checkedItems, setCheckedItems] = useState<number[]>([])
  const { visibleColumns, setVisibleColumns } = useGetInitialVisibleColumns({
    columns: PASS_TABLE_COLUMNS,
  })
  const { changePassIsDeleted } = useChangeIsDeleted()

  const { pathname } = useLocation()
  const navigate = useNavigate()

  const goToAction = () => {
    navigate(`${pathname}/action`)
  }

  const goToArchive = () => {
    navigate(`${pathname}/archive`)
  }

  return (
    <main>
      <div className={styles.top}>
        <h1 className={styles.top__title}>Пропуска</h1>
        <div className={styles.top__actions}>
          <Button
            text="Добавить пропуск"
            onClick={goToAction}
            icon={<Icon icon="plus" />}
          />
          <Button
            text="Архив удаленных данных"
            onClick={goToArchive}
            variant="transparent"
            icon={<Icon icon="archive" />}
          />
        </div>
      </div>
      <div className={styles.grid}>
        <Search
          editAction={changePassIsDeleted}
          columns={PASS_TABLE_COLUMNS}
          popupTitle="Пропуска"
          setSelectedIds={setCheckedItems}
          selectedIds={checkedItems}
          setVisibleColums={setVisibleColumns}
          visibleColums={visibleColumns}
        />
        <PassTable
          checkedItems={checkedItems}
          setCheckedItems={setCheckedItems}
          showOnlyDeletedItems={false}
          visibleColumns={visibleColumns}
        />
      </div>
    </main>
  )
}

export default Pass
