import { FormEventHandler, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import {
  useCreatePassMutation,
  useEditPassMutation,
  useLazyGetPassQuery,
} from "../../app/api/pass"
import { useGetRequestsQuery } from "../../app/api/requests"
import { PASS_STATUS_OPTIONS, VALIDITY_OPTIONS } from "../../services/constants"
import {
  OptionType,
  PassStatus,
  RequestStatus,
  ValidityEnum,
} from "../../services/types/types"
import { getRequestsOptions } from "../../services/utils/getOptions"
import GoBack from "../GoBack/GoBack"
import Button from "../ui/Button/Button"
import Input from "../ui/Input/Input"
import Select from "../ui/Select/Select"
import styles from "./styles.module.scss"

const PassAction = () => {
  const { id } = useParams()
  const [request, setRequest] = useState<OptionType | null>(null)
  const [passNumber, setPassNumber] = useState<string | null>(null)
  const [department, setDepartment] = useState<string | null>(null)
  const [position, setPosition] = useState<string | null>(null)
  const [phone, setPhone] = useState<string | null>(null)
  const [passStatus, setPassStatus] = useState<OptionType | null>(null)
  const [validity, setValidity] = useState<OptionType | null>(null)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [errorField, setErrorField] = useState<string | null>(null)

  const { data: requestsData } = useGetRequestsQuery({
    query: null,
    status: RequestStatus.resolved,
    showOnlyDeletedItems: false,
  })
  const [getPass] = useLazyGetPassQuery()
  const [editPass, { isLoading: editPassLoading }] = useEditPassMutation()
  const [createPass, { isLoading: createPassLoading }] = useCreatePassMutation()

  const navigate = useNavigate()

  const requestOptions = getRequestsOptions(requestsData)

  useEffect(() => {
    if (id) {
      getPass(id)
        .unwrap()
        .then((data) => {
          const attributes = data.data.attributes
          const requestData = attributes.request.data

          setRequest({
            label: requestData!.attributes.clientFullName,
            value: `${requestData!.id}`,
          })
          setPassNumber(attributes.passNumber)
          setDepartment(attributes.department)
          setPosition(attributes.position)
          setPhone(attributes.phone)
          setPassStatus({
            label: PASS_STATUS_OPTIONS.find(
              (item) => item.value === attributes.passStatus,
            )?.label!,
            value: attributes.passStatus,
          })
          setValidity({
            label: VALIDITY_OPTIONS.find(
              (item) => item.value === attributes.validity,
            )?.label!,
            value: attributes.validity,
          })
        })
    }
  }, [getPass, id])

  const onSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault()

    const param = {
      id,
      data: {
        department: department!,
        passNumber: passNumber!,
        passStatus: passStatus?.value!,
        phone: phone!,
        position: position!,
        request: request?.value!,
        validity: validity?.value!,
        isDeleted: false,
      },
    }

    try {
      if (id) {
        const response = await editPass(param)

        if ("error" in response) {
          setErrorMessage("Данное поле должно быть уникальным")
          setErrorField("Номер пропуска")
          return
        }
      } else {
        const response = await createPass(param)

        if ("error" in response) {
          setErrorMessage("Данное поле должно быть уникальным")
          setErrorField("Номер пропуска")
          return
        }
      }

      setRequest(null)
      setPassNumber(null)
      setDepartment(null)
      setPosition(null)
      setPhone(null)
      setPassStatus(null)
      setValidity(null)

      navigate("/pass/passes")
    } catch (error) {
      console.log(error)
    }
  }

  const filteredValidites = () => {
    if (passStatus?.value === PassStatus.temporary) {
      return VALIDITY_OPTIONS.filter(
        (option) => option.value === ValidityEnum.temporary,
      )
    }

    if (passStatus?.value === PassStatus.fired) {
      return VALIDITY_OPTIONS.filter((option) => {
        return option.value === ValidityEnum.indefinite
      })
    }

    if (passStatus?.value) {
      return VALIDITY_OPTIONS
    }

    return []
  }

  useEffect(() => {
    if (passStatus?.value === PassStatus.temporary) {
      setValidity({ label: "Временный", value: ValidityEnum.temporary })
    }

    if (passStatus?.value === PassStatus.fired) {
      setValidity({ label: "Бессрочный", value: ValidityEnum.indefinite })
    }
  }, [passStatus?.value])

  const disabled =
    !request?.value ||
    !passNumber?.trim() ||
    !department?.trim() ||
    !position?.trim() ||
    position.trim().length < 5 ||
    phone?.includes("_") ||
    !passStatus?.value ||
    !validity ||
    createPassLoading ||
    editPassLoading

  return (
    <main>
      <GoBack title={`${id ? "Редактировать" : "Добавить"} пропуск`} />
      <form className={styles.form} onSubmit={onSubmit}>
        <Select
          options={requestOptions}
          setOption={setRequest}
          value={request}
          label="Заявка"
          required
        />
        <Input
          label="Номер пропуска"
          required
          value={passNumber}
          onChange={setPassNumber}
          type="number"
        />
        <div className={styles.form__grid}>
          <Input
            onChange={setDepartment}
            value={department}
            label="Отдел"
            required
          />
          <Input
            onChange={setPosition}
            value={position}
            label="Должность"
            required
          />
          <Input
            onChange={setPhone}
            value={phone}
            label="Номер телефона"
            required
            type="tel"
          />
          <Select
            options={PASS_STATUS_OPTIONS}
            setOption={setPassStatus}
            value={passStatus}
            label="Статус пропуска"
            required
          />
          <Select
            options={filteredValidites()}
            setOption={setValidity}
            value={validity}
            label="Срок действия"
            required
          />
        </div>
        <Button text="Сохранить" onClick={() => {}} disabled={disabled} />
        {errorMessage && (
          <div className={styles.error}>{`${errorMessage}: ${errorField}`}</div>
        )}
      </form>
    </main>
  )
}

export default PassAction
