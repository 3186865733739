import { useEffect, useState } from "react"
import { ColumnType } from "../types/types"

interface Props {
  columns: ColumnType[]
}

export const useGetInitialVisibleColumns = ({ columns }: Props) => {
  const [visibleColumns, setVisibleColumns] = useState<{
    [key: string]: boolean
  }>({})

  useEffect(() => {
    columns.forEach((column) => {
      const item = { [column.value]: true }

      setVisibleColumns((prev) => ({ ...prev, ...item }))
    })
  }, [columns])

  return { visibleColumns, setVisibleColumns }
}
