import cn from "classnames"
import { useState } from "react"
import { createPortal } from "react-dom"
import { useLocation, useNavigate } from "react-router-dom"
import { useDeleteVisitMutation } from "../../app/api/visits"
import { useAppSelector } from "../../app/hooks"
import { VISIT_TABLE_COLUMNNS } from "../../services/constants"
import { useChangeIsDeleted } from "../../services/hooks/useChangeIsDeleted"
import { VisitItemType } from "../../services/types/types"
import { formatDateToRu } from "../../services/utils/formatDate"
import { removeSecondsInTime } from "../../services/utils/formatTime"
import { getGridStyles } from "../../services/utils/getGridStyles"
import { getDirectionText } from "../../services/utils/getTexts"
import PopupDelete from "../PopupDelete/PopupDelete"
import Preloader from "../ui/Preloader/Preloader"
import Icon from "../ui/icons"
import styles from "./styles.module.scss"

interface Props {
  item: VisitItemType
  checkedItems: number[]
  setCheckedItems: (arg: any) => void
  showOnlyDeletedItems: boolean
  visibleColumns: { [key: string]: boolean }
  allChecked: boolean
  setAllChecked: (arg: boolean) => void
}

const VisitRow = ({
  item,
  checkedItems,
  setCheckedItems,
  showOnlyDeletedItems,
  visibleColumns,
  allChecked,
  setAllChecked,
}: Props) => {
  const isAdmin = useAppSelector((state) => state.user.isAdmin)

  const [deleteVisit] = useDeleteVisitMutation()
  const { changeVisitIsDeleted, isLoading } = useChangeIsDeleted()

  const [isDeleteOpened, setIsDeleteOpened] = useState(false)

  const { pathname } = useLocation()
  const navigate = useNavigate()

  const urlParts = pathname.split("/")
  const isArchivePage = urlParts[urlParts.length - 1] !== "archive"

  const { date, time, direction, fullName, passNumber, kpp } = item.attributes

  const toggleChecked = (id: number) => {
    const finded = checkedItems.find((item) => item === id)

    if (finded) {
      const filtered = checkedItems.filter((item) => item !== finded)

      setCheckedItems(filtered)
    } else {
      setCheckedItems((prev: number[]) => [...prev, id])
    }
  }

  const deleteItem = async (id: number) => {
    isArchivePage
      ? await changeVisitIsDeleted({ id, isDeleted: true })
      : deleteVisit(id)
  }

  const restoreItem = async (id: number) => {
    try {
      await changeVisitIsDeleted({ id, isDeleted: false })
    } catch (error) {
      console.log(error)
    }
  }

  const goToAction = (id: number) => {
    navigate(`${pathname}/action/${id}`)
  }

  const style = getGridStyles({
    mainTable: VISIT_TABLE_COLUMNNS,
    visibleColumns,
  })
  const isChecked = checkedItems.some((_item) => _item === item.id)

  return (
    <li
      className={cn(styles.row, {
        [styles.row__hidden]: !isAdmin,
        [styles.row__arсhive]: showOnlyDeletedItems,
      })}
      style={style}
    >
      {isAdmin && (
        <div className={styles.row__item}>
          <input
            type="checkbox"
            onChange={() => toggleChecked(item.id)}
            checked={isChecked}
          />
        </div>
      )}
      {visibleColumns.date && (
        <div className={styles.row__item}>
          <span>{formatDateToRu(date)}</span>
        </div>
      )}
      {visibleColumns.time && (
        <div className={styles.row__item}>
          <span>{removeSecondsInTime(time)}</span>
        </div>
      )}
      {visibleColumns.fullName && (
        <div className={cn(styles.row__item, "oneLine")}>
          <span>{fullName}</span>
        </div>
      )}
      {visibleColumns.kpp && (
        <div className={styles.row__item}>
          <span>{kpp.data.attributes.name}</span>
        </div>
      )}
      {visibleColumns.direction && (
        <div className={styles.row__item}>
          <span>{getDirectionText(direction)}</span>
        </div>
      )}
      {visibleColumns.pass && (
        <div className={cn(styles.row__item, "oneLine")}>
          <span>Пропуск посетителя {passNumber}</span>
        </div>
      )}
      {visibleColumns.edit && (
        <div className={styles.row__item}>
          {showOnlyDeletedItems ? (
            <button onClick={() => restoreItem(item.id)}>
              <Icon icon={"arrowCircleDark"} />
            </button>
          ) : (
            <button onClick={() => goToAction(item.id)}>
              <Icon icon={"edit"} />
            </button>
          )}
        </div>
      )}
      {isAdmin && visibleColumns.delete && (
        <div className={styles.row__item}>
          <button onClick={() => setIsDeleteOpened(true)}>
            <Icon icon="trash" />
          </button>
        </div>
      )}
      {isLoading && createPortal(<Preloader />, document.body)}
      {isDeleteOpened &&
        createPortal(
          <PopupDelete
            action={deleteItem}
            ids={item.id}
            setIsPopupOpened={setIsDeleteOpened}
          />,
          document.body,
        )}
    </li>
  )
}

export default VisitRow
