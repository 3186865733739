import {
  useEditRequestMutation,
  useLazyGetRequestQuery,
} from "../../app/api/requests"
import { RequestItemType, RequestStatus } from "../../services/types/types"
import TransparentButton from "../ui/Button/Tranparent/TransparentButton"

interface Props {
  status: RequestStatus | undefined
  data?: RequestItemType
  setIsPopupOpened?: (value: boolean) => void
  selectedIds?: number[]
}

const StatusActions = ({
  status,
  data,
  setIsPopupOpened,
  selectedIds = undefined,
}: Props) => {
  const [changeStatus, { isLoading }] = useEditRequestMutation()
  const [getRequest] = useLazyGetRequestQuery()

  const change = async (status: RequestStatus) => {
    if (!selectedIds) {
      const attributes = data?.attributes

      const param = {
        id: `${data?.id}`,
        data: {
          requestStatus: status,
          requestNumber: attributes?.requestNumber,
          date: attributes?.date,
          passType: attributes?.passType,
          clientFullName: attributes?.clientFullName,
          organization: attributes?.organization,
          clientPhoto: attributes?.clientPhoto?.data.id,
          isDeleted: attributes?.isDeleted,
        },
      }

      await changeStatus(param)

      setIsPopupOpened?.(false)
    } else {
      selectedIds.forEach(async (id) => {
        const { data } = await getRequest(`${id}`)
        const attributes = data?.data.attributes

        const param = {
          id: `${id}`,
          data: {
            requestStatus: status,
            requestNumber: attributes?.requestNumber,
            date: attributes?.date,
            passType: attributes?.passType,
            clientFullName: attributes?.clientFullName,
            organization: attributes?.organization,
            clientPhoto: attributes?.clientPhoto?.data.id,
            isDeleted: attributes?.isDeleted,
          },
        }

        await changeStatus(param)
      })
    }
  }

  const actions =
    status === RequestStatus.pending ? (
      <>
        <TransparentButton
          text="Одобрить"
          onClick={() => change(RequestStatus.resolved)}
          variant="success"
          disabled={isLoading}
        />
        <TransparentButton
          text="Отклонить"
          onClick={() => change(RequestStatus.rejected)}
          variant="error"
          disabled={isLoading}
        />
      </>
    ) : (
      <TransparentButton
        text="Восстановить"
        onClick={() => change(RequestStatus.pending)}
        disabled={isLoading}
      />
    )

  return status === RequestStatus.resolved ? null : actions
}

export default StatusActions
