import { Outlet } from "react-router-dom"
import Aside from "../../components/Aside/Aside"
import Header from "../../components/Header/Header"
import styles from "./style.module.scss"

const System = () => {
  return (
    <div className="wrapper">
      <Aside />
      <div className={styles.content}>
        <Header />
        <Outlet />
      </div>
    </div>
  )
}

export default System
