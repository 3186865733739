const MapIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 18.5L9 17M9 17L3 20V7L9 4M9 17V4M9 4L15 7M15 7L21 4V11.5M15 7V12.5M19 18V18.01M21.121 20.121C21.5406 19.7015 21.8265 19.1669 21.9423 18.585C22.0581 18.003 21.9988 17.3997 21.7717 16.8515C21.5447 16.3033 21.1602 15.8347 20.6668 15.505C20.1734 15.1753 19.5934 14.9994 19 14.9994C18.4066 14.9994 17.8266 15.1753 17.3332 15.505C16.8398 15.8347 16.4553 16.3033 16.2283 16.8515C16.0012 17.3997 15.9419 18.003 16.0577 18.585C16.1735 19.1669 16.4594 19.7015 16.879 20.121C17.297 20.54 18.004 21.166 19 22C20.051 21.11 20.759 20.484 21.121 20.121Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default MapIcon
