import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useAppSelector } from "../../app/hooks"
import { REQUEST_TABLE_COLUMNS } from "../../services/constants"
import { useChangeIsDeleted } from "../../services/hooks/useChangeIsDeleted"
import { useGetInitialVisibleColumns } from "../../services/hooks/useGetInitialVisibleColumns"
import RequestsTable from "../RequestsTable/RequestsTable"
import Search from "../Search/Search"
import StatusFilters from "../StatusFilters/StatusFilters"
import Button from "../ui/Button/Button"
import Icon from "../ui/icons"
import styles from "./styles.module.scss"

const Requests = () => {
  const isAdmin = useAppSelector((state) => state.user.isAdmin)

  const { pathname } = useLocation()
  const navigate = useNavigate()

  const [checkedItems, setCheckedItems] = useState<number[]>([])
  const { visibleColumns, setVisibleColumns } = useGetInitialVisibleColumns({
    columns: REQUEST_TABLE_COLUMNS,
  })
  const { changeRequestIsDeleted } = useChangeIsDeleted()

  const goToAction = () => {
    navigate(`${pathname}/action`)
  }

  const goToArchive = () => {
    navigate(`${pathname}/archive`)
  }

  return (
    <main>
      <div className={styles.top}>
        <h1 className={styles.top__title}>Заявки на пропуск</h1>
        {isAdmin && <StatusFilters />}
        <div className={styles.top__action}>
          <Button
            text="Добавить заявку"
            onClick={goToAction}
            icon={<Icon icon="plus" />}
          />
          {isAdmin && (
            <Button
              text="Архив удаленных данных"
              onClick={goToArchive}
              variant="transparent"
              icon={<Icon icon="archive" />}
            />
          )}
        </div>
      </div>
      <div className={styles.grid}>
        <Search
          editAction={changeRequestIsDeleted}
          columns={REQUEST_TABLE_COLUMNS}
          popupTitle="Заявки на пропуск"
          selectedIds={checkedItems}
          confirm={true}
          setSelectedIds={setCheckedItems}
          setVisibleColums={setVisibleColumns}
          visibleColums={visibleColumns}
        />
        <RequestsTable
          checkedItems={checkedItems}
          setCheckedItems={setCheckedItems}
          visibleColumns={visibleColumns}
        />
      </div>
    </main>
  )
}

export default Requests
