import { useCallback, useState } from "react"
import { useEditPassMutation, useLazyGetPassQuery } from "../../app/api/pass"
import {
  useEditRequestMutation,
  useLazyGetRequestQuery,
} from "../../app/api/requests"
import {
  useEditVisitMutation,
  useLazyGetVisitQuery,
} from "../../app/api/visits"
import {
  IEditPassParams,
  IEditRequestParams,
  IEditVisitParams,
} from "../types/api"

interface Props {
  id: number | null
  isDeleted: boolean
}

// хук для изменения поля isDeleted в страпи. Для каждой сущности экспортируется своя функиця
// для запросов на заявку - changeRequestIsDeleted, для визита - changeVisitIsDeleted и т.д.
// каждуй хук принимает id элемента, который нужно изменить и значение isDeleted, которое надо установить

export const useChangeIsDeleted = () => {
  const [getRequest] = useLazyGetRequestQuery()
  const [getVisit] = useLazyGetVisitQuery()
  const [getPass] = useLazyGetPassQuery()

  const [editRequest] = useEditRequestMutation()
  const [editPass] = useEditPassMutation()
  const [editVisit] = useEditVisitMutation()

  const [isLoading, setIsLoading] = useState(false)

  const changeRequestIsDeleted = useCallback(
    async ({ id, isDeleted }: Props) => {
      const item = await getRequest(`${id}`).unwrap()
      const attributes = item.data?.attributes

      const param: IEditRequestParams = {
        id: `${id}`,
        data: {
          clientFullName: attributes.clientFullName,
          clientPhoto: attributes.clientPhoto?.data.id,
          date: attributes.date,
          organization: attributes.organization,
          passType: attributes.passType,
          requestNumber: attributes.requestNumber,
          requestStatus: attributes.requestStatus,
          isDeleted,
        },
      }
      try {
        setIsLoading(true)
        await editRequest(param)
      } catch (error) {
        console.log(error)
      } finally {
        setIsLoading(false)
      }
    },
    [editRequest, getRequest],
  )

  const changeVisitIsDeleted = async ({ id, isDeleted }: Props) => {
    const item = await getVisit(`${id}`).unwrap()
    const attributes = item?.data?.attributes

    const param: IEditVisitParams = {
      id: `${id}`,
      data: {
        date: attributes.date,
        direction: attributes.direction,
        fullName: attributes.fullName,
        kpp: `${attributes.kpp.data.id}`,
        passNumber: attributes.passNumber,
        time: attributes.time,
        isDeleted,
      },
    }

    try {
      setIsLoading(true)
      await editVisit(param)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const changePassIsDeleted = useCallback(
    async ({ id, isDeleted }: Props) => {
      const item = await getPass(`${id}`).unwrap()
      const attributes = item.data?.attributes

      const param: IEditPassParams = {
        id: `${id}`,
        data: {
          department: attributes.department,
          passNumber: attributes.passNumber,
          passStatus: attributes.passStatus,
          phone: attributes.phone,
          position: attributes.position,
          validity: attributes.validity,
          isDeleted,
        },
      }

      try {
        setIsLoading(true)
        await editPass(param)
      } catch (error) {
        console.log(error)
      } finally {
        setIsLoading(false)
      }
    },
    [editPass, getPass],
  )

  return {
    changeRequestIsDeleted,
    changeVisitIsDeleted,
    changePassIsDeleted,
    isLoading,
  }
}
