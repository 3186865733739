import Cookies from "js-cookie"
import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom"
import "./App.scss"
import { useGetMeQuery } from "./app/api/user"
import { useAppSelector } from "./app/hooks"
import LoginPage from "./pages/Login/Login"
import Main from "./pages/Main/Main"
import PassPage from "./pages/Pass/Pass"
import PassActionPage from "./pages/PassAction/PassAction"
import PassArchivePage from "./pages/PassArchive/PassArchive"
import RequestActionPage from "./pages/RequestAction/RequestAction"
import RequestArchivePage from "./pages/RequestArchive/RequestArchive"
import RequestsPage from "./pages/Requests/Requests"
import System from "./pages/System/System"
import VisitArchivePage from "./pages/VisitArchive/VisitArchive"
import VisitsPage from "./pages/Visits/Visits"
import VisitsActionPage from "./pages/VisitsAction/VisitsAction"

function App() {
  const { isLoading } = useGetMeQuery(Cookies.get("token"))
  const { authorized, isAdmin } = useAppSelector((state) => state.user)

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Main />,
      children: [
        {
          path: "/login",
          element: authorized ? (
            <Navigate to={"/pass/requests"} />
          ) : (
            <LoginPage />
          ),
        },
      ],
    },
    {
      path: "/pass",
      element: authorized ? <System /> : <Navigate to={"/login"} />,
      children: [
        {
          path: "visits",
          element: <VisitsPage />,
        },
        {
          path: "visits/action/:id?",
          element: <VisitsActionPage />,
        },
        {
          path: "visits/archive",
          element: <VisitArchivePage />,
        },
        {
          path: "requests",
          element: <RequestsPage />,
        },
        {
          path: "requests/action/:id?",
          element: <RequestActionPage />,
        },
        {
          path: "requests/archive",
          element: <RequestArchivePage />,
        },
        {
          path: "passes",
          element: isAdmin ? <PassPage /> : <Navigate to={"/pass/requests"} />,
        },
        {
          path: "passes/action/:id?",
          element: isAdmin ? (
            <PassActionPage />
          ) : (
            <Navigate to={"/pass/requests"} />
          ),
        },
        {
          path: "passes/archive",
          element: isAdmin ? (
            <PassArchivePage />
          ) : (
            <Navigate to={"/pass/requests"} />
          ),
        },
      ],
    },
  ])

  return (
    <>
      {isLoading && !authorized ? (
        <p>Loading...</p>
      ) : (
        <RouterProvider router={router} />
      )}
    </>
  )
}

export default App
