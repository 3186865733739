import cn from "classnames"
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react"
import { getImgFromStrapi } from "../../services/utils/getImgFromStrapi"
import Icon from "../ui/icons"
import styles from "./styles.module.scss"

interface Props {
  setClientImg: Dispatch<
    SetStateAction<string | ArrayBuffer | null | undefined>
  >
  setFiles: Dispatch<SetStateAction<FileList | null>>
  clientPhotoUrl?: string | null
  isError?: boolean
}

const PhotoUpload = ({
  setClientImg,
  setFiles,
  clientPhotoUrl = null,
  isError = false,
}: Props) => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [imgSrc, setImgSrc] = useState<string | ArrayBuffer | null>(null)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  const hangeFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    setErrorMessage(null)
    setImgSrc(null)

    const file = event.target.files?.[0]
    const reader = new FileReader()

    // размер файла не должен быть больше 1MB
    if (file?.size && file.size > 1000000) {
      return setErrorMessage("Размер файла не должен иметь вес более 1МБ")
    }

    reader.onload = (e) => {
      setImgSrc(e.target?.result!)
    }

    setFiles(event.target.files)

    setClientImg(file?.name)
    reader.readAsDataURL(file!)
  }

  useEffect(() => {
    if (clientPhotoUrl) setImgSrc(getImgFromStrapi(clientPhotoUrl))
  }, [clientPhotoUrl])

  useEffect(() => {
    if (isError) setImgSrc(null)
  }, [isError])

  return (
    <>
      <div className={styles.upload} onClick={() => inputRef.current?.click()}>
        <Icon icon="upload" />
        <span>Нажмите сюда, чтобы загрузить изображение</span>
        <input
          onChange={hangeFileUpload}
          ref={inputRef}
          accept="image/png, image/jpeg"
          type="file"
        />
        {imgSrc && (
          <img
            src={imgSrc as string}
            alt=""
            className={cn(styles.img, {
              [styles.img__shown]: imgSrc,
            })}
            onLoad={(e) => setIsLoading(false)}
          />
        )}
      </div>
      {errorMessage && <div className={styles.error}>{errorMessage}</div>}
    </>
  )
}

export default PhotoUpload
