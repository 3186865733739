import { useState } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { removeCreds } from "../../app/slices/user"
import OperatorImg from "../../img/operator.png"
import UserImg from "../../img/person.png"
import DatePickerComponent from "../ui/DatePicker/DatePicker"
import Icon from "../ui/icons"
import styles from "./styles.module.scss"

const Header = () => {
  const isAdmin = useAppSelector((state) => state.user.isAdmin)
  const dispatch = useAppDispatch()

  const [date, setDate] = useState<Date | null>(null)

  const logout = () => {
    dispatch(removeCreds())
  }
  const [openCalendar,setOpenCalendar] = useState<boolean>(false)

  const toggle = (dateRef:any) => {
    if (!openCalendar){
        setOpenCalendar(true)
        dateRef?.current && dateRef.current.setOpen(true)
    }
    else {
        setOpenCalendar(false)
        dateRef?.current && dateRef.current.setOpen(false)
    }
}
  return (
    <header className={styles.header}>
      <button className={styles.header__calendar}>
        <Icon icon="calendar" />
        <DatePickerComponent toggle={toggle} selected={date} setSelected={setDate} hideInput />
      </button>
      <button>
        <Icon icon="bell" />
      </button>
      <div className={styles.header__user}>
        <div className={styles.header__userImg}>
          <img src={isAdmin ? UserImg : OperatorImg} alt="user" />
        </div>
        <span>{isAdmin ? "Администратор" : "Оператор"}</span>
        <Icon icon="chevron" />
      </div>
      <button onClick={logout} className={styles.header__logout}>
        <Icon icon="logout" />
      </button>
    </header>
  )
}

export default Header
