import { useState } from "react"
import { useDeleteVisitMutation } from "../../app/api/visits"
import { VISIT_TABLE_COLUMNNS } from "../../services/constants"
import { useChangeIsDeleted } from "../../services/hooks/useChangeIsDeleted"
import { useGetInitialVisibleColumns } from "../../services/hooks/useGetInitialVisibleColumns"
import GoBack from "../GoBack/GoBack"
import Search from "../Search/Search"
import VisitsTable from "../VisitsTable/VisitsTable"
import styles from "./styles.module.scss"

const VisitsArchive = () => {
  const [checkedItems, setCheckedItems] = useState<number[]>([])
  const { visibleColumns } = useGetInitialVisibleColumns({
    columns: VISIT_TABLE_COLUMNNS,
  })
  const [deleteVisit] = useDeleteVisitMutation()
  const { changeVisitIsDeleted } = useChangeIsDeleted()

  return (
    <main>
      <GoBack title="История посещения объекта" archive={true} />
      <div className={styles.grid}>
        <Search
          deleteAction={deleteVisit}
          editAction={changeVisitIsDeleted}
          selectedIds={checkedItems}
          needSettings={false}
          isArchive={true}
          setSelectedIds={setCheckedItems}
          visibleColums={visibleColumns}
        />
        <VisitsTable
          checkedItems={checkedItems}
          setCheckedItems={setCheckedItems}
          showOnlyDeletedItems={true}
          visibleColumns={visibleColumns}
        />
      </div>
    </main>
  )
}

export default VisitsArchive
