import { useEffect, useState } from "react"

interface Props {
  string: string | null
  delay: number
}

export const useDebounced = ({ string, delay }: Props) => {
  const [debouncedValue, setDebouncedValue] = useState<string | null>(null)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedValue(string)
    }, delay)

    return () => clearTimeout(timeout)
  }, [delay, string])

  return debouncedValue
}
